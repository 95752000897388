import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

import alertmsg from "./modules/alertmsg";
import addresses from "./modules/addresses";
import regions from "./modules/regions";
import dadata from "./modules/dadata";
import countries from "./modules/countries";
import notifications from "./modules/notifications";
import auth from "./modules/auth";
import users from "./modules/users";
import manufacturers from "./modules/manufacturers";
import partners from "./modules/partners";
import selfretails from "./modules/selfretails";
import requests from "./modules/requests";
import certificates from "./modules/certificates";
import tables from "./modules/tables";


Vue.use(Vuex);

// initial state
const initialState = {
  alertmsg: JSON.parse(JSON.stringify(alertmsg.state)),
  addresses: JSON.parse(JSON.stringify(addresses.state)),
  regions: JSON.parse(JSON.stringify(regions.state)),
  dadata: JSON.parse(JSON.stringify(dadata.state)),
  countries: JSON.parse(JSON.stringify(countries.state)),
  notifications: JSON.parse(JSON.stringify(notifications.state)),
  auth: JSON.parse(JSON.stringify(Object.assign({}, auth.state, {token: '', tokenexp: 0, tokenexp_pending: false, user: {}}))),
  users: JSON.parse(JSON.stringify(users.state)),
  manufacturers: JSON.parse(JSON.stringify(manufacturers.state)),
  partners: JSON.parse(JSON.stringify(partners.state)),
  selfretails: JSON.parse(JSON.stringify(selfretails.state)),
  requests: JSON.parse(JSON.stringify(requests.state)),  
  certificates: JSON.parse(JSON.stringify(certificates.state)),  
  tables: JSON.parse(JSON.stringify(tables.state)),  
};

const debug = process.env.NODE_ENV !== "production";
export default new Vuex.Store({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  modules: {    
    alertmsg,
    addresses,
    regions,
    dadata,
    countries,
    notifications,
    auth,
    users,
    manufacturers,
    partners,
    selfretails,
    requests,    
    certificates,
    tables
  },
  mutations: {
    reset(state) {
      Object.keys(state).forEach(key => {
        Object.assign(state[key], initialState[key]);
      });
    }
  }
});
