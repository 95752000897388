<template>
  <b-sidebar id="sidebar-right" :visible="show" lazy right shadow backdrop width="50%" @hidden="$emit('update:show', false)">
    <div class="px-3 py-2">
      <component :is="form" :formData="formData" :mode="mode" v-on="$listeners"></component>
    </div>    
  </b-sidebar>
</template>

<script>
import {
  FakeForm,
  UserForm,
  ManufacturerForm, 
  PartnerForm,
  SelfRetailForm,
  OfficeForm,
  RequestCertificateForm
}
from '@/components/forms'

export default {
  name: "RightSidebar",
  components: {
    FakeForm,
    UserForm,
    ManufacturerForm,
    PartnerForm,
    SelfRetailForm,
    OfficeForm,
    RequestCertificateForm
  },
  props: {
    show: Boolean,
    mode: String,
    form: String,
    formData: Object
  }
}
</script>

<style lang="scss" scoped></style>