import { HttpService } from "../HttpService";

export class DealersService extends HttpService {

  fetchManufacturerDealerAddresses = async ({id, manufacturerId}) => {
    return await this._$get(`/dealers/${id}/${manufacturerId}/addresses`).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchAllDealerAddresses = async ({id, params}) => {
    return await this._$get(`/dealers/${id}/addresses`, {params: params}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchPartners = async (params) => {
    return await this._$get("/dealers", {params: params}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchUsersByPartnerRegion = async ({id, search}) => {
    const params = (search) ? {search: search} : null
    return await this._$get(`/dealers/${id}/users`, {params: params}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  changeActivePartnerUser = async ({id, userId}) => {
    return await this._$post(`/dealers/${id}/user/${userId}`).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  dealerVerify = async () => {
    return await this._$get("/dealers/verify").catch((err) => console.error(`$DealerService Error::${err}`))
  };

  dealerVerifyDetails = async () => {
    return await this._$get("/dealers/verifydetails").catch((err) => console.error(`$DealerService Error::${err}`))
  };

  dealerDataConfirm = async payload => {
    return await this._$put("/dealers/confirmed", {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  dealerDataWrong = async payload => {
    return await this._$put("/dealers/notconfirmed", {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchDealersByInn = async ({ inn, countryID }) => {
    return await this._$get(`/dealers/autocomplete?inn=${inn}&countryId=${countryID}`).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  createDealer = async payload => {
    return await this._$post("/dealers/createnew", {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  editDealer = async payload => {
    return await this._$post(`/dealers/edit`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  adminEditDealer = async ({id, payload}) => {
    return await this._$post(`/dealers/${id}`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  confirmFirstManager = async ({id, payload}) => {
    return this._$put(`/dealers/${id}/manager1approvedealercreation`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  rejectManager = async ({id, payload}) => {
    return this._$put(`/dealers/${id}/manager1rejectdealercreation`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  rejectManagerForCorrection = async ({id, payload}) => {
    return this._$post(`/dealers/${id}/manager1rejectforcorrection`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  confirmLeader = async ({id, payload}) => {
    return this._$put(`/dealers/${id}/leaderapprovedealercreation`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  rejectLeader = async ({id, payload}) => {
    return this._$put(`/dealers/${id}/leaderrejectdealercreation`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  rejectLeaderForCorrection = async ({id, payload}) => {
    return this._$post(`/dealers/${id}/leaderrejectforcorrection`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  addDealerAddress = async payload => {
    return await this._$post("/dealers/adddealeraddress", {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchPartner = async id => {
    return await this._$get(`/dealers/${id}/details`).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchPartnerAddresses = async ({id, params}) => {
    return await this._$get(`/dealers/${id}/addresses`, {params: params}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchDealerCertificates = async params => {
    return await this._$get("/dealers/certificatesbymanufacturer", {params: params}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchAllDealerCertificates = async params => {
    return await this._$get("/dealers/certificates", {params: params}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  fetchAddressCertificateHistory = async ({id, addressId}) => {
    return await this._$get(`/dealers/${id}/address/${addressId}/certificates`).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  changePartnerData = async ({id, payload}) => {
    return this._$post(`/dealers/${id}/change`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  changeAddress = async ({id, payload}) => {
    return this._$post(`/dealers/${id}/changeaddress`, {body: payload}).catch((err) => console.error(`$DealerService Error::${err}`))
  };

  changeManufacturerInfo = async id => {
    return this._$put(`/dealers/${id}/showmanufacturerinfo`).catch((err) => console.error(`$DealerService Error::${err}`))
  };

}