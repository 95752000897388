import { HttpService } from "../HttpService";

export class DaDataService extends HttpService {

  fetchOrganizations = async (payload) => {
    return await this._$post("/dadata/organizations", {body: payload}).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  fetchAddresses = async (payload) => {
    return await this._$post("/dadata/addresses", {body: payload}).catch((err) => console.error(`$HttpService Error::${err}`))
  };

}