import { HttpService } from "../HttpService";

export class AuthService extends HttpService {
  
  loginRequest = async payload => {
    return this._$post("/account/login", {body: payload}).catch((err) => console.error(`$AuthService Error::${err}`))
  };

  logOutRequest = async payload => {
    return this._$post("/account/logout", {body: payload}).catch((err) => console.error(`$AuthService Error::${err}`))
  };

  setIsAgree = async () => {
    return this._$post("/account/agree").catch((err) => console.error(`$AuthService Error::${err}`))
  };
}