import RehauStore from "@/store/lib";
import { DaDataService } from "@/services"

const daDataService = new DaDataService()

const dadata = new RehauStore({
  state: {
    organizations: [],
    addresses: []
  }
})
.action("fetchOrganizations", {
  serviceFn: daDataService.fetchOrganizations,
  property: "organizations"
})
.action("fetchAddresses", {
  serviceFn: daDataService.fetchAddresses,
  property: "addresses"
})
.getStore()

export default dadata;