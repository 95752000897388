<template>
  <footer class="footer row">
    <p class="mb-0">Узнайте больше о Сертификате качества РЕХАУ по ссылке <a href="https://rhsolutions.ru/certification/">rhsolutions.ru/certification</a></p>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent'
}
</script>

<style lang="scss">
.footer {
  font-family: "BrixSansBold", Arial, sans-serif;
  height: 100px;
  background: #000;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  @media (max-width: 1020px) {
    font-size: 14px !important;
  }
  p {
    padding: 5px 30px;
  }
  a {
    color: $red;
  }
}
</style>
