import MunufacturerVerify from '@/views/manufacturers/verify.vue'

const manufacturersRoutes = [
  {
    path: '/manufacturers',
    name: 'Manufacturers',
    component: () => import('@/views/manufacturers/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manufacturers/:id',
    name: 'ManufacturerDetails',
    component: () => import('@/views/manufacturers/_id.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/manufacturer/verify',
    name: 'ManufacturerVerify',
    component: MunufacturerVerify,
    meta: { requiresAuth: true },
  }
]

export default manufacturersRoutes