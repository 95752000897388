<template>
  <div class="certificate-component">
    <div class="certificate-component__wrapper">
      <div class="certificate-component__header">
        <div>
          <div class="certificate-component__header-title">Решения</div>
          <div>для дома и бизнеса</div>
        </div>
        <img class="certificate-component__header-logo" src="../assets/logo_top_empty.svg" alt="Логотип">
      </div>

      <div class="certificate-component__main">
        <div class="certificate-component__main-title">Сертификат</div>
        <div class="certificate-component__main-qr">
          <img :src="qr" alt="qr-код сертификата" />
        </div>
        <div class="certificate-component__main-bottom-title">Центр сертификации партнеров</div>
        <div class="certificate-component__main-bottom-text">
          Для проверки подлинности сертификата <br>считайте QR-код с помощью смартфона
        </div>
      </div>
    </div>
    <div class="certificate-component__footer">
      <div class="certificate-component__footer-descr">Сертифицированный {{role}}</div>
      <div class="certificate-component__footer-name">{{title}}</div>
      <div class="certificate-component__footer-logo">
        <img src="../assets/logo_new.svg" alt="Логотип РЕХАУ">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CertificateComponent',
  props: ['title','role','qr']

}
</script>

<style lang="scss">
.certificate-component {
  font-family: Inter, Arial, sans-serif;
  font-weight: 400;
  line-height: 1;

  &__wrapper {
    padding: 29px 24px;
    background-color: $green;
    color: white;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-size: 10px;

    &-title {
      font-weight: 700;
    }

    &-logo {
      max-width: 130px;
      min-width: auto;
      margin-top: -29px;
    }
  }

  &__main {
    margin: 0 auto 14px;
    width: min-content;

    &-title {
      margin-bottom: 12px;
      font-size: 38px;
      font-weight: 700;
    }

    &-qr {
      max-width: 240px;
      margin-bottom: 12px;
    }

    &-bottom-title {
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }

    &-bottom-title {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
    }

    &-bottom-text {
      font-size: 10px;
      font-weight: 500;
      text-align: center;
    }
  }

  &__footer {
    padding: 31px 24px;
    background-color: white;
    color: black;
    border: 1px solid $gray;
    border-top: none;

    &-descr {
      margin-bottom: 10px;
      font-size: 13px;
    }

    &-name {
      margin-bottom: 26px;
      font-size: 20px;
      font-weight: 700;
    }

    &-logo {
      display: flex;
      justify-content: flex-end;

      img {
        width: 100%;
        max-width: 194px;
        min-width: auto;
        margin-right: -18px;
        margin-bottom: -30px;
      }
    }
  }
}
</style>