import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle, faEnvelope, faCopy, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Fragment } from 'vue-frag'
import Highlighter from "vue-highlight-words";
import Inputmask from 'inputmask'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.directive('mask', {
  bind: function (el, binding) {    
    Inputmask(binding.value).mask(el);
  },
  update: function (el, binding) {
    Inputmask(binding.value).mask(el);
  }, 
  unbind: function(el) {
    Inputmask.remove(el);
  }
});

const getPatternKeypressHandler = function (el, pattern) {
  const re = new RegExp(pattern);
  return function ($ev) {
    if (!re.test($ev.key) && !(
      // delete, backpsace, tab, escape, enter,
      ([46, 8, 9, 27, 13].indexOf($ev.keyCode) !== -1 ||
      // Ctrl+A
      ($ev.ctrlKey === true && $ev.keyCode === 65) ||
      // Ctrl+C
      ($ev.ctrlKey === true && $ev.keyCode === 67) ||
      // Ctrl+X
      ($ev.ctrlKey === true && $ev.keyCode === 88) ||
      // home, end, left, right
      ($ev.keyCode >= 35 && $ev.keyCode <= 39))
    ))
      $ev.preventDefault();
  };
};
const getRangeInputHandler = function(el, range) {
  return function ($ev) {
    const value = $ev.target.value;
    if (value.length > range.max)
      $ev.target.value = value.slice(0, range.max)
    else if (value.length > 0 && value.length !== range.min && value.length !== range.max)
      $ev.target.setCustomValidity(range.message)
    else
      $ev.target.setCustomValidity('')
  }
};
const getRangePasteHandler = function(el, binding) {
  const bv = binding.value;
  return function ($ev) {
    let paste = ($ev.clipboardData || window.clipboardData).getData('text');
    if (paste.length > 0 && paste.length !== bv.min && paste.length !== bv.max)
      $ev.target.setCustomValidity(bv.message)
    else
      $ev.target.setCustomValidity('')
  }
};
Vue.directive('validate', {
  bind: function (el, binding) {
    if (binding.value && binding.value.pattern) {
      el.keypressHandler = getPatternKeypressHandler(el, binding.value.pattern)
      el.addEventListener('keypress', el.keypressHandler)
    }
    if (binding.value && binding.value.range) {
      el.inputHandler = getRangeInputHandler(el, binding.value.range)
      el.addEventListener('input', el.inputHandler)
      el.addEventListener('change', el.inputHandler)
      el.pasteHandler = getRangePasteHandler(el, binding.value.range)
      el.addEventListener('paste', el.pasteHandler)
    }
  },
  update: function (el, binding) {
    if (binding.value && binding.value.pattern) {
      el.keypressHandler = getPatternKeypressHandler(el, binding.value.pattern)
      el.addEventListener('keypress', el.keypressHandler)
    }
    if (binding.value && binding.value.range) {
      el.inputHandler = getRangeInputHandler(el, binding.value.range)
      el.addEventListener('input', el.inputHandler)
      el.addEventListener('change', el.inputHandler)
      el.pasteHandler = getRangePasteHandler(el, binding.value.range)
      el.addEventListener('paste', el.pasteHandler)
    }
  }, 
  unbind: function(el) {
    if (el.keypressHandler)
      el.removeEventListener('keypress', el.keypressHandler)
      if (el.inputHandler){
        el.removeEventListener('input', el.inputHandler)
        el.removeEventListener('change', el.inputHandler)
      }
    if (el.pasteHandler)
      el.removeEventListener('paste', el.pasteHandler)
  }
});
Vue.use(BootstrapVue)

library.add(faUserCircle)
library.add(faEnvelope)
library.add(faCopy)
library.add(faEnvelopeOpen)
 
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('Fragment', Fragment)
Vue.component('Highlighter', Highlighter)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
