export class Resource {
  constructor(options) {
    this.getters = {}
    this.actions = {}
    this.state = options.state || {}    
  }

  getter(getterName, getterFn) {
    this.getters[getterName] = getterFn
    return this 
  }

  action(actionName, options) {
    this.actions[actionName] = {
      actionFn: options.actionFn,
      serviceFn: options.serviceFn,  
      thenFn: options.thenFn,
      property: options.property,
      beforeRequest: options.beforeRequest,
      onSuccess: options.onSuccess,
      onError: options.onError,
      dispatchString: this.getDispatchString(actionName),
      commitString: this.getCommitString(actionName),
    }
    return this    
  }  

  getDispatchString(action) {
    return action
  }

  getCommitString(action) {
    const capitalizedAction = action.replace(/([A-Z])/g, "_$1").toUpperCase()
    return capitalizedAction
  }
}
