<template>
  <FormWithActions :mode="mode" :loading="loading" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <b-form-select v-model="officeForm.countryId" :options="countriesDD" :disabled="countryDesabled" @change="onCountryChange" required>
        <template #first>
          <b-form-select-option :value="null" disabled>Страна</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Наименование офиса" v-model="officeForm.spotName"></b-form-input>
    </b-form-group>

    <b-form-group v-if="isAdmin">
      <AddressAutocomplete
        ref="address"
        placeholder="Адрес офиса"
        v-model="officeForm.spotAddress"
        :country="countryISO"
        :readonly="!countrySelected"
        required
        @select:suggestion="onSelectAddressSuggection"
      ></AddressAutocomplete>
      <small v-if="countryISO && countryISO !== 'RU'" class="form-text text-muted">
        Введите адрес в формате Страна, Область, Город, Улица, Дом
      </small>
    </b-form-group>

    <b-form-group v-if="isAdmin && countryISO && countryISO !== 'RU'">
      <b-form-input
        v-model="officeForm.spotAddressCoordinates"
        placeholder="Координаты"
        :readonly="!countrySelected"
        required
        type="text"
        name="coordinates"
        pattern="(-)?([1-8]?\d(\.\d+)?|90(\.0+)?),\s(-)?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)"
        title="Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)"
      >
      </b-form-input>
      <small class="form-text text-muted">
        Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)
      </small>
    </b-form-group>

    <b-form-group>
      <PhoneNumber placeholder="Телефонный номер" v-model="officeForm.spotAddressPhone" :country="countryISO" :readonly="!countrySelected" required></PhoneNumber>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Менеджер офиса продаж" v-model="officeForm.spotAccost" required></b-form-input>
    </b-form-group>
  </FormWithActions>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { PhoneNumber, AddressAutocomplete } from "@/components/forms/elements";
import FormWithActions from "./FormWithActions.vue";

export default {
  name: "OfficeForm",
  components: {
    FormWithActions,
    PhoneNumber,
    AddressAutocomplete
  },
  props: {
    mode: String,
    formData: Object,
  },
  data() {
    return {
      loading: false,
      officeForm: { ...{ countryId: null, spotAddressCoordinates: null }, ...this.formData },
      oldAddress: this.formData.spotAddress,
      suggestionAddress: null
    };
  },
  methods: {
    ...mapActions(["showErrorAlert"]),
    ...mapActions("countries", ["fetchCountries"]),
    ...mapActions("partners", ["changeOffice"]),

    onCountryChange() {
      this.officeForm = {
        countryId: this.officeForm.countryId,
        spotName: null,
        spotAddress: null,
        spotAddressCoordinates: null,
        latitude: null,
        longitude: null,
        spotAddressPhone: null,
        spotAccost: null,
      };
    },

    onSelectAddressSuggection(suggestion) {
      this.officeForm.spotAddress = this.suggestionAddress = suggestion.address;
      this.officeForm.latitude = suggestion.latitude;
      this.officeForm.longitude = suggestion.longitude;
    },

    onSaveClick() {
      if (this.countryISO === 'RU' && this.officeForm.spotAddress !== this.oldAddress && this.officeForm.spotAddress !== this.suggestionAddress) {
        this.showErrorAlert("Адрес не соответствует предложенному списку адресов!");
        this.$nextTick(() => {
          const addressRef = this.$refs.address;
          addressRef.$el.firstChild.focus();
        });
        return;
      }
      this._saveOffice();
    },
    onCancelClick() {
      this._closeSideBar();
    },

    _saveOffice() {
      const method = this.changeOffice;
      const args = {
        id: this.officeForm.partnerId,
        payload: {
          ...this.officeForm,
          countryId: this.officeForm.countryId,
          countryCode: this.countryISO,
          latitude: this.officeForm.latitude || parseFloat(this.officeForm.spotAddressCoordinates?.split(', ').at(0)),
          longitude: this.officeForm.longitude || parseFloat(this.officeForm.spotAddressCoordinates?.split(', ').at(1)),
        },
      };
      this.loading = true;
      method(args).then((res) => {
        this.loading = false;
        if (!res.ok) return;

        this.$emit("saved:form-data");

        this._closeSideBar();
      });
    },
    _closeSideBar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapGetters(["isAdmin"]),
    ...mapGetters("countries", ["countriesDD"]),
    ...mapState('partners', {
      partner: state => state.partner
    }),

    isEditMode() {
      return this.mode === "EDIT";
    },

    countryDesabled() {
      return this.isEditMode
    },
    countrySelected() {
      return this.officeForm.countryId != null;
    },
    countryISO() {
      return ((this.countriesDD || []).find((c) => c.value === this.officeForm.countryId) || {}).iso;
    },
  },
  created() {
    this.fetchCountries(this.user.regionId).then((res) => {
      if (res.data.length === 1) this.officeForm.countryId = res.data[0].id;
    });
  },
};
</script>
