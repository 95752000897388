import * as cloneDeep from "lodash.clonedeep";
import store from "@/store";

class StoreCreator {
  constructor(resource, options) {
    this.resource = resource;
    this.options = Object.assign(
      {
        createStateFn: false,
        namespaced: true
      },
      options
    );

    this.store = this.createStore();
  }

  createState() {
    if (this.options.createStateFn) {
      return this.createStateFn();
    } else {
      return this.createStateObject();
    }
  }

  createStateObject() {
    const resourceState = cloneDeep(this.resource.state);
    const state = Object.assign(
      {
        pending: {},
        error: {}
      },
      resourceState
    );
    return state;
  }

  createStateFn() {
    return () => this.createStateObject();
  }

  createGetters() {
    const storeGetters = {};

    const getters = this.resource.getters || [];
    Object.keys(getters).forEach(getter => {
      storeGetters[getter] = state => getters[getter](state);
    });
    return storeGetters;
  }

  createActions() {
    const storeActions = {};

    const actions = this.resource.actions || [];
    Object.keys(actions).forEach(action => {
      const { dispatchString, commitString, actionFn, serviceFn } = actions[action];

      if (actionFn) {
        storeActions[dispatchString] = async (context, params) => {
          return new Promise(resolve => resolve(actionFn(context, params)));
        };
      } else {
        const serviceFnAsync = params =>
          serviceFn instanceof Promise
            ? serviceFn(params)
            : new Promise(resolve => resolve((serviceFn || function(p) {return p || 0;})(params)));

        storeActions[dispatchString] = async (context, params) => {
          
          if (!['tokenExpired', 'showTokenExpiredAlert'].includes(dispatchString) && 
              store.getters.getTokenExp > 0 && Date.now() >= store.getters.getTokenExp
          ) {
            if (!store.state.auth.pending.token_expired && !store.state.auth.tokenexp_pending)
              context.dispatch('tokenExpired', null, {root: true});
            return
          }

          context.commit(commitString, params, context);
          return serviceFnAsync(params).then(
            response => {
              if (response.ok === true) {
                context.commit(`${commitString}_SUCCESS`, {
                  data: response.data,
                  params,
                  context
                });
              } else {
                context.commit(`${commitString}_ERROR`, {
                  error: response.errorMessage,
                  params,
                  context
                });
              }
              return Promise.resolve(response);
            },
            error => {
              context.commit(`${commitString}_ERROR`, {
                error: error,
                params,
                context
              });
              return Promise.reject(error);
            }
          );
        };
      }
    });

    return storeActions;
  }

  createMutations(defaultState) {
    const mutations = {};

    const actions = this.resource.actions;
    Object.keys(actions)
      .filter(a => !actions[a].actionFn)
      .forEach(action => {
        const { property, commitString, beforeRequest, onSuccess, onError } = actions[action];
        const commitProperty = property || commitString.toLowerCase();

        mutations[`${commitString}`] = (state, payload) => {
          if (commitProperty !== null) {
            state.pending[commitProperty] = true;
            state.error[commitProperty] = null;
          }

          if (beforeRequest) {
            beforeRequest(state, payload);
          }
        };
        mutations[`${commitString}_SUCCESS`] = (state, payload) => {
          if (commitProperty !== null) {
            state.pending[commitProperty] = false;
            state.error[commitProperty] = null;
          }

          if (onSuccess) {
            onSuccess(state, payload);
          } else if (commitProperty !== null) {
            state[commitProperty] = payload.data;
          }
        };
        mutations[`${commitString}_ERROR`] = (state, payload) => {
          if (commitProperty !== null) {
            state.pending[commitProperty] = false;
            state.error[commitProperty] = payload.error;
          }

          if (onError) {
            onError(state, payload);
          } else if (commitProperty !== null) {
            // sets property to it's default value in case of an error
            state[commitProperty] = defaultState[commitProperty];
          }
        };
      });

    return mutations;
  }

  createStore() {
    const state = this.createState();
    return {
      namespaced: this.options.namespaced,
      state,
      getters: this.createGetters(state),
      mutations: this.createMutations(state),
      actions: this.createActions()
    };
  }
}

export function createStore(resource, options) {
  return new StoreCreator(resource, options).store;
}
