<template>
  <b-form-input
    type="text"
    :placeholder="placeholder"
    :value="innValue"
    v-mask="innMask"
    @input="$emit('input', $event)"
    :readonly="readonly"
    :required="required"
    :disabled="disabled"
    autocomplete="off"
  />
</template>

<script>
export default {
  name: 'FakeInn',
  props: {
    value: String,
    placeholder: String,
    country: { type: String, default: null },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },    
  },
  data() {
    return { 
      innValue: this.value,
      innMask: '99999999999[99]'
    };
  },
  computed: {    
  }

}
</script>

<style lang="sass" scoped></style>