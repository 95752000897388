<template>
  <b-form-input
    type="tel"
    :placeholder="placeholder"
    :value="phoneValue"
    v-mask="phoneMask"
    @input="$emit('input', $event)"
    :readonly="readonly"
    :required="required"
    :disabled="disabled"
    autocomplete="off"
  />
</template>

<script>
import {PHONE_MASKS} from "@/constants"

export default {
  name: 'PhoneNumber',
  props: {
    value: String,
    placeholder: String,
    country: {type: String, default: null},
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      phoneValue: this.value
    };
  },
  computed: {
    phoneMask() {
      return PHONE_MASKS[this.country] || ''
    }
  },
  watch: {
    value(nv, ov) {
      if (ov && !nv ) {
        this.phoneValue = nv;
      }
    }
  },
}
</script>

<style lang="sass" scoped></style>
