import { HttpService } from "../HttpService";

export class RequestsService extends HttpService {

  fetchRequestStatuses = async id => {
    return await this._$get(`/requests/${id}/statuses`).catch((err) => console.error(`$RequestService Error::${err}`))
  };

  fetchRequests = async (params) => {
    return await this._$get("/requests", {params: params}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  fetchRequest = async id => {
    return await this._$get(`/requests/${id}/details`).catch(err => console.error(`$RequestService Error::${err}`))
  };

  addressCertificationRequests = async id => {
    return await this._$get(`/requests/confirmedrequestbyaddress/${id}`).catch(err => console.error(`$RequestService Error::${err}`))
  };

  getManufacturerRequestData = async id => {
    return await this._$get(`/requests/edit/${id}`).catch(err => console.error(`$RequestService Error::${err}`))
  };

  editManufacturerRequestData = async ({id, payload}) => {
    return await this._$post(`/requests/edit/${id}`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  // Requests for Self Retails
  managerApproveAddSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/managerapproveaddselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  managerRejectAddSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/managerrejectaddselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  managerRejectForCorrectionAddSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/managerrejectforcorrectionaddselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  leaderApproveAddSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/leaderapproveaddselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  leaderRejectAddSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/leaderrejectaddselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  leaderRejectForCorrectionAddSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/leaderrejectforcorrectionaddselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  managerApproveCertificationSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/managerapprovecertificationselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  managerRejectCertificationSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/managerrejectсertificationselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  leaderApproveCertificationSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/leaderapproveсertificationselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

  leaderRejectCertificationSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/leaderrejectcertificationselfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };  

  fetchRequestSelfRetail = async id => {
    return await this._$get(`/requests/${id}/selfretail`).catch(err => console.error(`$RequestService Error::${err}`))
  };

  editRequestSelfRetail = async ({id, payload}) => {
    return await this._$post(`/requests/${id}/selfretail`, {body: payload}).catch(err => console.error(`$RequestService Error::${err}`))
  };

}