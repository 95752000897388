import { render, staticRenderFns } from "./HighlighterIf.vue?vue&type=template&id=8fc44ff2&scoped=true"
import script from "./HighlighterIf.vue?vue&type=script&lang=js"
export * from "./HighlighterIf.vue?vue&type=script&lang=js"
import style0 from "./HighlighterIf.vue?vue&type=style&index=0&id=8fc44ff2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8fc44ff2",
  null
  
)

export default component.exports