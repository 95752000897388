export const INN_PLACEHOLDERS = {
  'RU': 'ИНН',
  'BY': 'УНП',
  'KZ': 'БИН',
  'GE': 'Ид. код',
  'AM': 'УНН',
}

export const INN_LENGTH = {
  'RU': {min: 10, max: 12},
  'BY': {min: 9, max: 9},
  'KZ': {min: 12, max: 12},
  'GE': {min: 9, max: 11},
  'AM': {min: 8, max: 8},
}

export const INN_MASKS = {
  'RU': '9999999999[99]',
  'BY': '999999999',
  'KZ': '999999999999',
  'GE': '999999999[99]',
  'AM': '99999999',
}
