<template>
  <AutocompleteInput
    :placeholder="placeholder"
    :value="addressQuery"
    suggestion-field="address"
    :query-func="queryFunction"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    v-on="$listeners"
  >
    <template #suggestion="{ suggestion, query }">
      <HighlighterIf :text="suggestion.address" :words="query" />
    </template>
  </AutocompleteInput>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AutocompleteInput from "./AutocompleteInput.vue";
import HighlighterIf from "./HighlighterIf.vue";

export default {
  name: "AddressAutocomplete",
  components: {
    AutocompleteInput,
    HighlighterIf,
  },
  props: {
    value: String,
    country: [Number, String],
    placeholder: String,
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      addressQuery: this.value
    };
  },
  methods: {
    ...mapActions("dadata", ["fetchAddresses"]),
    queryFunction(query) {
      if (this.country !== 'RU') {
        return
      }

      return this.fetchAddresses({
        countryIsoCode: this.country,
        query: query,
      }).then((res => {
        return res.ok ? res.data : []
      }))
    }
  },
  watch: {
    value(nv) {
      this.addressQuery = nv
    }
  },
  computed: {
    ...mapState("dadata", {
      addresses: (state) => state.addresses,
    }),
  },
};
</script>

<style scoped></style>
