import Vue from 'vue'
import VueRouter from 'vue-router'
import { Role } from '@/constants'
import authRoutes from './modules/auth'
import usersRoutes from './modules/users'
import manufacturersRoutes from './modules/manufacturers'
import partnersRoutes from './modules/partners'
import selfretailsRoutes from './modules/selfretsils'
import requestsRoutes from './modules/requests'
import certificatesRoutes from './modules/certificates'

Vue.use(VueRouter)

const defaultRoutes = [
  // HOME for All
  {
    path: '*',
    name: 'Home',
    //component: () => import('@/App.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const user = JSON.parse(localStorage.getItem("auth-user"))
      if (user) {
        if (user.role == Role.Admin)
          next('/users')
        else if (user.role == Role.Leader || user.role == Role.Manager || user.role == Role.Curator)
          next(`/manufacturers`)
        else if (user.role == Role.Manufacturer)
          next('/certificates')
        else if (user.role == Role.Dealer)
          next('/offices')
        else
          next()
      }
      else
        next()
    }
  }
];

const routes = [
  ...authRoutes,
  ...usersRoutes,
  ...manufacturersRoutes,
  ...partnersRoutes,
  ...selfretailsRoutes,
  ...requestsRoutes,
  ...certificatesRoutes,
  ...defaultRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('auth-token') == null) {
        next({
            path: '/login',
            params: { nextUrl: to.fullPath }
        })
    }
    else {
      const user = JSON.parse(localStorage.getItem("auth-user"));

      if (
        [Role.Leader, Role.Manager, Role.Curator].includes(user.role)
        && ![
          'Manufacturers',
          'ManufacturerDetails',
          'Partners',
          'PartnerDetails',
          'Requests',
          'RequestDetails',
          'Profile',
          'CheckAgree',
        ].includes(to.name)
      ) {
        next('/manufacturers');
      } else if(
        user.role === Role.Dealer
        && ![
          'PartnerOffices',
          'DealerOfficeCertificate',
          'DealerOfficeCertificatePreview',
          'Profile',
          'PartnerVerify',
          'CheckAgree',
        ].includes(to.name)
      ) {
        next('/offices')
      }
      else if(
        user.role === Role.Manufacturer
        && ['PartnerOffices'].includes(to.name)
      ) {
        next('/certificates')
      }
      else {
        next();
      }
    }
  }
  else {
      next()
  }
})

export default router
