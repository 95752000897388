import RehauStore from "@/store/lib";
import { Messages } from '@/constants'
import { ManufacturersService, DealersService, SelfRetailCertificatesService } from "@/services";

const manufacturersService = new ManufacturersService()
const dealersService = new DealersService()
const selfRetailCertificatesService = new SelfRetailCertificatesService()

const selfretails = new RehauStore({
  state: {
    selfretails: [],
    selfretailsTotal: null,

    selfretail: {},
    manufacturer: {},

    partnersByInn: {},
    partnerExists: false,
    showAddNewPartnerForm: false,

    selfretailCertificate: {},
    selfretailCertificateHistory: [],
    selfretailCertificateHistoryTotal: null,
  }
})
.action("fetchSelfRetails", {
  serviceFn: manufacturersService.fetchSelfRetails,
  onSuccess: (state, {data}) => {
    state.selfretails = data.items
    state.selfretailsTotal = data.totalItems
  },
})
.action("fetchSelfRetail", {
  serviceFn: manufacturersService.fetchSelfRetail,
  property: "selfretail"
})
.action("addSelfRetail", {
  serviceFn: manufacturersService.createSelfRetail,
  onSuccess: (state, {context}) => {
    context.dispatch("showSuccessAlert", Messages.Success.SelfRetailAdded, {root: true});
  },
  onError: (state, {error, context}) => {
    context.dispatch("showErrorAlert", error || Messages.Error.SelfRetailAdded, {root: true});
  }
})
.action("editSelfRetail", {
  serviceFn: manufacturersService.updateSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.SelfRetailUpdate, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.SelfRetailUpdate, {root: true});
  }
})
.action("fetchManufacturerById", {
  serviceFn: manufacturersService.fetchManufacturer,
  property: "manufacturer"
})
.action("fetchDealersByInn", {
  serviceFn: dealersService.getDealersList,
  onSuccess: (state, {data}) => {
    state.partnersByInn = data
    state.partnerExists = data.length;
    state.showAddNewPartnerForm = !data.length;
  }
})
.action("fetchSelfRetailCertificate", {
  serviceFn: selfRetailCertificatesService.fetchSelfRetailCertificate,
  property: "selfretailCertificate"
})
.action("fetchSelfRetailCertificateHistory", {
  serviceFn: selfRetailCertificatesService.fetchSelfRetailCertificates,
  onSuccess: (state, {data}) => {
    state.selfretailCertificateHistory = data.items
    state.selfretailCertificateHistoryTotal = data.totalItems
  }
})
.action("selfretailCertificateRevocation", {
  serviceFn: selfRetailCertificatesService.selfretailCertificateRevocation,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.SelfRetailCertificateRevocation, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.SelfRetailCertificateRevocation, {root: true});
  }
})
.action("toggleManufacturerInfo", {
  serviceFn: manufacturersService.changeManufacturerInfo,
  onSuccess: (state, {data}) => {
    console.log(data);
    //context.dispatch("showSuccessAlert", Messages.Success.DealerEdited, {root: true});
    state.manufacturer.isShowManufacturerInfoInCertificate = data.currentValue
  },
  onError: (state, {error, context}) => {
    context.dispatch("showErrorAlert", error, {root: true});
  }
})
.getStore()

export default selfretails;