<template>
  <FormWithActions :mode="mode" :loading="loading" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <b-form-select v-model="selfretailForm.countryId" :options="countriesDD" @change="onCountryChange" :disabled="countryDesabled" required>
        <template #first>
          <b-form-select-option :value="null" disabled>Страна</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <b-form-input
        placeholder="Название офиса"
        v-model="selfretailForm.spotName"
        :readonly="!countrySelected"
        :disabled="isRequestEditSendMode && selfretailForm.isApproved"
        required
      ></b-form-input>
    </b-form-group>

    <b-form-group>
      <AddressAutocomplete
        placeholder="Адрес офиса"
        :value="selfretailForm.spotAddress"
        @input="selfretailForm.spotAddress = $event"
        @select:suggestion="onSelectAddressSuggection"
        :country="countryISO"
        :readonly="!countrySelected"
        :disabled="isRequestEditSendMode && selfretailForm.isApproved"
        required
      ></AddressAutocomplete>
      <small v-if="countryISO && countryISO !== 'RU'" class="form-text text-muted">
        Введите адрес в формате Страна, Область, Город, Улица, Дом
      </small>
    </b-form-group>

    <b-form-group v-if="countryISO && countryISO !== 'RU'">
      <b-form-input
        v-model="selfretailForm.spotAddressCoordinates"
        placeholder="Координаты"
        :readonly="!countrySelected"
        :disabled="isRequestEditSendMode && selfretailForm.isApproved"
        required
        type="text"
        name="coordinates"
        pattern="(-)?([1-8]?\d(\.\d+)?|90(\.0+)?),\s(-)?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)"
        title="Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)"
      >
      </b-form-input>
      <small class="form-text text-muted">
        Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)
      </small>
    </b-form-group>

    <b-form-group>
      <PhoneNumber
        placeholder="Телефонный номер"
        v-model="selfretailForm.spotAddressPhone"
        :country="countryISO"
        :readonly="!countrySelected"
        :disabled="isRequestEditSendMode && selfretailForm.isApproved"
        required
      ></PhoneNumber>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Менеджер офиса продаж" v-model="selfretailForm.spotAccost" :disabled="isRequestEditSendMode && selfretailForm.isApproved" required></b-form-input>
    </b-form-group>
  </FormWithActions>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { PhoneNumber, AddressAutocomplete } from "@/components/forms/elements";
import FormWithActions from "./FormWithActions.vue";

export default {
  name: "SelfRetailForm",
  components: {
    PhoneNumber,
    AddressAutocomplete,
    FormWithActions,
  },
  props: {
    mode: String,
    formData: Object,
  },
  data() {
    return {
      loading: false,
      selfretailForm: { ...{ countryId: null, spotAddressCoordinates: null }, ...this.formData },
      oldAddress: this.formData.spotAddress,
      suggestionAddress: null
    };
  },
  methods: {
    ...mapActions(["showErrorAlert"]),
    ...mapActions("countries", ["fetchCountries"]),
    ...mapActions("selfretails", ["fetchManufacturerById", "addSelfRetail", "editSelfRetail"]),
    ...mapActions("requests", ["editRequestSelfRetail"]),

    onCountryChange() {
      this.selfretailForm = {
        countryId: this.selfretailForm.countryId,
        spotAddress: null,
        latitude: null,
        longitude: null,
        spotName: null,
        spotAddressCoordinates: null,
        spotAddressPhone: null,
        spotAccost: null,
      }
    },

    onSelectAddressSuggection(suggestion) {
      this.selfretailForm.spotAddress = this.suggestionAddress = suggestion.address;
      this.selfretailForm.latitude = suggestion.latitude;
      this.selfretailForm.longitude = suggestion.longitude;
    },

    onSaveClick() {
      if (this.countryISO === 'RU' && this.selfretailForm.spotAddress !== this.oldAddress && this.selfretailForm.spotAddress !== this.suggestionAddress) {
        this.showErrorAlert("Адрес не соответствует предложенному списку адресов!");
        this.$nextTick(() => {
          const addressRef = this.$refs.address;
          addressRef.$el.firstChild.focus();
        });
        return;
      }
      this._saveSelfRetail();
    },
    onCancelClick() {
      this._closeSideBar();
    },

    _saveSelfRetail() {
      const payload = {
        ...this.selfretailForm,
        latitude: this.selfretailForm.latitude || parseFloat(this.selfretailForm.spotAddressCoordinates?.split(', ').at(0)),
        longitude: this.selfretailForm.longitude || parseFloat(this.selfretailForm.spotAddressCoordinates?.split(', ').at(1)),
        countryId: this.selfretailForm.countryId,
        countryCode: this.countryISO,
      };
      let method = null;
      let args = null;
      if (this.isAddMode) {
        method = this.addSelfRetail;
        args = { id: this.manufacturerId, payload: payload };
      } else if (this.isEditMode) {
        if (this.isRequestEditSendMode) {
          method = this.editRequestSelfRetail;
          args = { id: this.selfretailForm.requestId, payload: payload };
        } else {
          method = this.editSelfRetail;
          args = { id: this.manufacturerId, retailId: this.selfretailForm.id, payload: payload };
        }
      }

      this.loading = true;
      method(args).then((res) => {
        this.loading = false;
        if (!res.ok) return;

        this.$emit("saved:form-data");

        this._closeSideBar();
      });
    },
    _closeSideBar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState({
      user: (state) => state.auth.user,
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapGetters("countries", ["countriesDD"]),
    ...mapState("selfretails", {
      manufacturer: (state) => state.manufacturer,
    }),

    manufacturerId() {
      return (this.isAdmin) ? this.$route.params.id : this.manufacturer.id;
    },

    isAddMode() {
      return this.mode === "ADD";
    },
    isEditMode() {
      return this.mode.includes("EDIT");
    },
    isRequestEditSendMode() {
      return this.mode === "EDIT:SEND";
    },

    countryDesabled() {
      return this.isEditMode || this.selfretailForm.isApproved;
    },
    countrySelected() {
      return this.selfretailForm.countryId != null;
    },
    countryISO() {
      return ((this.countriesDD || []).find((c) => c.value === this.selfretailForm.countryId) || {}).iso;
    },
  },
  created() {
    if (!this.manufacturer.id && !this.isAdmin) this.fetchManufacturerById(this.partnerId);
    this.fetchCountries(this.user.regionId).then((res) => {
      if (res.data.length === 1) this.selfretailForm.countryId = res.data[0].id;
    });
  },
};
</script>

<style scoped></style>
