<template>
  <div class="dadata_container">
    <b-form-input
      type="text"
      autocomplete="off"
      v-validate="validateRule"
      :placeholder="placeholder"
      :value="inputQuery"
      :readonly="readonly"
      :required="required"
      :disabled="disabled"
      @input="onInputChange"
      @keydown="onKeyPress"
      @focus="onInputFocus"
      @blur="onInputBlur"
    />
    <div v-if="inputFocused && suggestionsVisible" class="dadata_suggestions">
      <div
        v-for="(suggestion, index) in suggestions"
        :key="`sug_${index}`"
        :class="['dadata_sug_item', { ['dadata_sug_item_current']: index === suggestionIndex }]"
        @mousedown="onSuggestionClick(index)"
      >
        <slot name="suggestion" :suggestion="suggestion" :query="highlightQuery" />
      </div>
    </div>
  </div>
</template>

<script>
import { isObject } from '@/utils';

export default {
  name: "AutocompleteInput",
  props: {
    value: String,
    placeholder: String,
    validateRule: { type:Object, default: null },
    queryFunc: { type: Function, default: () => Promise.resolve([]) },
    suggestionField: String,
    country: { type: String, default: null },
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    autocomplete: { type: String, default: 'off' }
  },
  data() {
    return {
      inputQuery: this.value,
      highlightQuery: null,
      inputFocused: false,
      suggestions: [],
      suggestionIndex: -1,
      suggestionsVisible: true,
      isValid: false,

      timeoutId: null,
    };
  },
  methods: {
    onInputChange(event) {
      clearTimeout(this.timeoutId);
      if (event.length <= 0) {
        this.suggestions = [];
        this.suggestionsVisible = false;
        return;
      }
      this.highlightQuery = event;
      this.$emit("input", event);

      this.timeoutId = setTimeout(() => {
        this.queryFunc(event).then((res) => {
          this.suggestions = res;
          this.suggestionsVisible = true;
        });
      }, 300);
    },
    onInputFocus() {
      this.inputFocused = true;
    },
    onInputBlur() {
      this.inputFocused = false;
    },
    onKeyPress() {
      const ARROW_DOWN = 40;
      const ARROW_UP = 38;
      const ENTER = 13;
      if (event.which === ARROW_DOWN && this.suggestionsVisible) {
        event.preventDefault();
        if (this.suggestionIndex < this.suggestions.length - 1) {
          this.suggestionIndex = this.suggestionIndex + 1;
        }
      } else if (event.which === ARROW_UP && this.suggestionsVisible) {
        event.preventDefault();
        if (this.suggestionIndex >= 0) {
          this.suggestionIndex = this.suggestionIndex - 1;
        }
      } else if (event.which === ENTER) {
        event.preventDefault();
        if (this.suggestionIndex >= 0) {
          this._selectSuggestion(this.suggestionIndex);
        }
      }
    },
    onSuggestionClick(index) {
      this._selectSuggestion(index);
    },

    _selectSuggestion(index) {
      if (this.suggestions.length >= index - 1) {
        this.suggestionsVisible = false;
        const suggestion = this.suggestions[index];
        const value = isObject(suggestion) ? suggestion[this.suggestionField] : suggestion;
        this.inputQuery = value;
        this.$emit("select:suggestion", suggestion);
      }
    },
  },
  watch: {
    value(nv) {
      this.inputQuery = nv
    }
  },
};
</script>

<style scoped>
.dadata_container {
  width: 100%;
  position: relative;
}
.dadata_input {
}
.dadata_suggestions {
  position: absolute;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.dadata_sug_item {
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
}
.dadata_sug_item:hover {
  background: #87e2cd;
  /* color: white; */
}
.dadata_sug_item_current {
  background: #87e2cd;
  /* color: white; */
}
</style>
