<template>
  <FormWithActions :mode="mode" :loading="loading" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <b-form-select v-model="partnerForm.countryId" :options="countriesDD" :disabled="countryDesabled" @change="onCountryChange" required>
        <template #first>
          <b-form-select-option :value="null" disabled>Страна</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <InnAutocomplete
        placeholder="ИНН"
        v-model="partnerForm.inn"
        :country="countryISO"
        :find-inn="fetchPartnersByInn"
        :find-inn-suggestions="partnersByInn"
        :readonly="!countrySelected"
        :disabled="partnerForm.isPartnerApproved"
        required
        @inn-exists="onInnExists"
        @select:suggestion="onSelectInnSuggection"
      ></InnAutocomplete>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Наименование" v-model="partnerForm.name" :readonly="!countrySelected" :disabled="partnerForm.isPartnerApproved" required></b-form-input>
    </b-form-group>

    <template v-if="haveExistsOffices">
      <b-form-group>
        <b-form-checkbox id="chbNewOffice" v-model="switchNewAddress" name="chbNewOffice">Другой адрес</b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="!switchNewAddress">
        <b-form-select v-model="partnerForm.addressId" :options="addresses" required>
          <template #first>
            <b-form-select-option :value="null" disabled>Выберите адрес партнера</b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>
    </template>

    <template v-if="isEditMode || isNewPartner">
      <b-form-group>
        <b-form-input placeholder="Имя" v-model="partnerForm.contactPersonFirstName" :disabled="partnerForm.isUserApproved || isAdminEditMode" required></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input placeholder="Фамилия" v-model="partnerForm.contactPersonLastName" :disabled="partnerForm.isUserApproved || isAdminEditMode" required></b-form-input>
      </b-form-group>

      <b-form-group>
        <b-form-input placeholder="Email" v-model="partnerForm.contactPersonEmail" type="email" :disabled="partnerForm.isUserApproved || isChangeProfileData || isAdminEditMode" required></b-form-input>
      </b-form-group>

      <b-form-group>
        <PhoneNumber
          placeholder="Телефонный номер"
          v-model="partnerForm.contactPersonPhone"
          :country="countryISO"
          :readonly="!countrySelected"
          :disabled="partnerForm.isUserApproved"
          required
        ></PhoneNumber>
      </b-form-group>

      <b-form-group>
        <b-form-input placeholder="Вебсайт" v-model="partnerForm.webSite" required></b-form-input>
      </b-form-group>
    </template>

    <template v-if="(isEditMode && !isAdminEditMode && !isChangeProfileData) || isNewOffice">
      <b-form-group>
        <b-form-input placeholder="Название офиса" v-model="partnerForm.spotName" :disabled="partnerForm.isAddressApproved" required></b-form-input>
      </b-form-group>

      <b-form-group>
        <AddressAutocomplete
          ref="address"
          placeholder="Адрес офиса"
          v-model="partnerForm.spotAddress"
          :country="countryISO"
          :readonly="!countrySelected"
          :disabled="partnerForm.isAddressApproved"
          required
          @select:suggestion="onSelectAddressSuggection"
        ></AddressAutocomplete>
        <small v-if="countryISO && countryISO !== 'RU'" class="form-text text-muted">
          Введите адрес в формате Страна, Область, Город, Улица, Дом
        </small>
      </b-form-group>

      <b-form-group v-if="countryISO && countryISO !== 'RU'">
        <b-form-input
          v-model="partnerForm.spotAddressCoordinates"
          placeholder="Координаты"
          :readonly="!countrySelected"
          required
          type="text"
          name="coordinates"
          pattern="(-)?([1-8]?\d(\.\d+)?|90(\.0+)?),\s(-)?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)"
          title="Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)"
        >
        </b-form-input>
        <small class="form-text text-muted">
          Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)
        </small>
      </b-form-group>

      <b-form-group>
        <PhoneNumber
          placeholder="Телефонный номер"
          v-model="partnerForm.spotAddressPhone"
          :country="countryISO"
          :readonly="!countrySelected"
          :disabled="partnerForm.isAddressApproved"
          required
        ></PhoneNumber>
      </b-form-group>

      <b-form-group>
        <b-form-input placeholder="Менеджер офиса продаж" v-model="partnerForm.spotAccost" :disabled="partnerForm.isAddressApproved" required></b-form-input>
      </b-form-group>
    </template>
  </FormWithActions>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { INN_LENGTH } from "@/constants";
import { InnAutocomplete, PhoneNumber, AddressAutocomplete } from "@/components/forms/elements";
import FormWithActions from "./FormWithActions.vue";

const checkInnLength = (country, inn) => {
  const innlength = INN_LENGTH[country];
  return innlength && inn.length >= innlength.min && inn.length <= innlength.max;
};

export default {
  name: "PartnerForm",
  components: {
    InnAutocomplete,
    PhoneNumber,
    AddressAutocomplete,
    FormWithActions,
  },
  props: {
    mode: String,
    formData: Object,
  },
  data() {
    return {
      loading: false,
      findExists: this.mode === "ADD",
      switchNewAddress: false,
      partnerForm: { ...{ countryId: null, inn: null, name: null, spotAddress: null, spotAddressCoordinates: null }, ...this.formData },
      oldAddress: this.formData.spotAddress,
      suggestionAddress: null,
      addresses: [],
    };
  },
  methods: {
    ...mapActions(["showErrorAlert"]),
    ...mapActions("countries", ["fetchCountries"]),
    ...mapActions("partners", ["fetchPartnersByInn", "addPartner", "addOffice", "editPartner", "adminEditPartner", "changePartnerData"]),
    ...mapActions("requests", ["editManufacturerRequestData"]),

    onCountryChange() {
      this.partnerForm = {
        ...this.partnerForm,
        inn: '',
        name: null,
        addressId: null,
        spotAddress: null,
        latitude: null,
        longitude: null,
        spotAccost: null,
        spotAddressPhone: null,
        spotAddressCoordinates: null,
        spotName: null,
        webSite: null,
        contactPersonPhone: null,
        contactPersonEmail: null,
        contactPersonLastName: null,
        contactPersonFirstName: null,
      };
    },

    onInnExists(exists) {
      this.findExists = exists;
    },

    onSelectInnSuggection(suggestion) {
      this.partnerForm.inn = suggestion.inn;
      this.partnerForm.name = suggestion.name;
      if (this.findExists) {
        this.addresses = suggestion.addresses;
        this.partnerForm.addressId = this.addresses.length === 1 ? this.addresses[0].value : null;
      } else {
        this.partnerForm.spotAddress = this.suggestionAddress = suggestion.address;
        this.partnerForm.latitude = suggestion.latitude;
        this.partnerForm.longitude = suggestion.longitude;
      }
    },
    onSelectAddressSuggection(suggestion) {
      this.partnerForm.spotAddress = this.suggestionAddress = suggestion.address;
      this.partnerForm.latitude = suggestion.latitude;
      this.partnerForm.longitude = suggestion.longitude;
    },

    onSaveClick() {
      if (this.countryISO === 'RU' && this.partnerForm.spotAddress !== this.oldAddress && this.partnerForm.spotAddress !== this.suggestionAddress) {
        this.showErrorAlert("Адрес не соответствует предложенному списку адресов!");
        this.$nextTick(() => {
          const addressRef = this.$refs.address;
          addressRef.$el.firstChild.focus();
        });
        return;
      }
      this._savePartner();
    },
    onCancelClick() {
      this._closeSideBar();
    },

    _savePartner() {
      const payload = {
        ...this.partnerForm,
        dealerId: this.partnerForm.id,
        addressId: this.isAddNewOfficeMode ? null : this.partnerForm.addressId,
        countryId: this.partnerForm.countryId,
        countryCode: this.countryISO,
        addressCountryId: this.partnerForm.countryId,
        addressCountryCode: this.countryISO,
      };

      const spotAddressCoordinates = this.partnerForm.spotAddressCoordinates?.split(', ');
      const latitude = this.partnerForm.latitude || (spotAddressCoordinates && parseFloat(spotAddressCoordinates.at(0)));
      const longitude = this.partnerForm.longitude || (spotAddressCoordinates && parseFloat(spotAddressCoordinates.at(1)));
      const partnerCoordinates = this.partnerForm.spotAddressCoordinates;

      if (latitude) {
        payload.latitude = latitude;
      }

      if (longitude) {
        payload.longitude = longitude;
      }

      if (partnerCoordinates) {
        payload.partnerCoordinates = partnerCoordinates;
      }

      let method = null;
      let args = null;
      if (this.isAddMode) {
        method = this.addPartner;
        args = payload;
      } else if (this.isAddNewOfficeMode || this.isAddExistsOfficeMode) {
        method = this.addOffice;
        args = payload;
      } else if (this.isEditMode) {
        if (this.isAdminEditMode) {
          method = this.adminEditPartner;
          args = { id: this.partnerForm.id, payload: payload };
        } else if (this.isRequestEditSendMode) {
          method = this.editManufacturerRequestData;
          args = { id: this.partnerForm.requestId, payload: payload };
        } else if (this.isChangeProfileData) {
          method = this.changePartnerData;
          args = { id: this.partnerForm.id, payload: payload };
        } else {
          method = this.editPartner;
          args = payload;
        }
      }
      this.loading = true;
      method(args).then((res) => {
        this.loading = false;
        if (!res.ok) return;

        this.$emit("saved:form-data");

        this._closeSideBar();
      });
    },
    _closeSideBar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
  },
  computed: {
    ...mapGetters(["userRole", "isAdmin"]),
    ...mapState({
      user: (state) => state.auth.user,
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapGetters("countries", ["countriesDD"]),
    ...mapState("partners", {
      partnersByInn: (state) => state.partnersByInn,
    }),

    isFindExistMode() {
      return this.findExists;
    },
    isAddMode() {
      return !this.findExists && this.mode === "ADD";
    },
    isAddNewOfficeMode() {
      return this.findExists && this.mode === "ADD" && this.isNewOffice;
    },
    isAddExistsOfficeMode() {
      return this.findExists && this.mode === "ADD";
    },

    isEditMode() {
      return this.mode.includes("EDIT");
    },
    isAdminEditMode() {
      return this.isAdmin && this.mode === "EDIT";
    },
    isRequestEditSendMode() {
      return this.mode === "EDIT:SEND";
    },
    isChangeProfileData() {
      return this.mode === "EDIT:PROFILE";
    },

    haveExistsOffices() {
      return this.findExists && this.addresses.length > 0;
    },
    isNewPartner() {
      return !this.findExists && checkInnLength(this.countryISO, this.partnerForm.inn?.replace(/_/g, ""));
    },
    isNewOffice() {
      return (!this.isEditMode && this.isNewPartner) || this.switchNewAddress;
    },

    countryDesabled() {
      return this.isChangeProfileData || this.partnerForm.isPartnerApproved;
    },
    countrySelected() {
      return this.partnerForm.countryId != null;
    },
    countryISO() {
      return ((this.countriesDD || []).find((c) => c.value === this.partnerForm.countryId) || {}).iso;
    },
  },
  created() {
    this.fetchCountries(this.user.regionId).then((res) => {
      if (res.data.length === 1) this.partnerForm.countryId = res.data[0].id;
    });
  },
};
</script>

<style scoped></style>
