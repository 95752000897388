<template>
  <FormWithActions :mode="mode" :loading="loading" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <b-form-select v-model="manufacturerForm.countryId" :options="countriesDD" @change="onCountryChange" :disabled="isChangeProfileData" required>
        <template #first>
          <b-form-select-option :value="null" disabled>Страна</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <InnAutocomplete
        placeholder="ИНН"
        v-model="manufacturerForm.inn"
        :country="countryISO"
        :readonly="!countrySelected"
        required
        @select:suggestion="onSelectInnSuggection"
      ></InnAutocomplete>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Наименование" v-model="manufacturerForm.name" :readonly="!countrySelected" required></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Имя" v-model="manufacturerForm.contactPersonFirstName" :disabled="isAdminEditMode" required></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Фамилия" v-model="manufacturerForm.contactPersonLastName" :disabled="isAdminEditMode" required></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Email" v-model="manufacturerForm.contactPersonEmail" type="email" :disabled="isAdminEditMode || isChangeProfileData" required></b-form-input>
    </b-form-group>

    <b-form-group>
      <AddressAutocomplete
        ref="address"
        placeholder="Адрес"
        :value="manufacturerForm.address"
        @input="manufacturerForm.address = $event"
        @select:suggestion="onSelectAddressSuggection"
        :country="countryISO"
        :readonly="!countrySelected"
        required
      ></AddressAutocomplete>
      <small v-if="countryISO && countryISO !== 'RU'" class="form-text text-muted">
        Введите адрес в формате Страна, Область, Город, Улица, Дом
      </small>
    </b-form-group>

    <b-form-group v-if="countryISO && countryISO !== 'RU'">
      <b-form-input
        v-model="manufacturerForm.coordinates"
        placeholder="Координаты"
        :readonly="!countrySelected"
        required
        type="text"
        name="coordinates"
        pattern="(-)?([1-8]?\d(\.\d+)?|90(\.0+)?),\s(-)?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)"
        title="Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)"
      >
      </b-form-input>
      <small class="form-text text-muted">
        Введите координаты в формате широта, долгота (например, 55.752957, 37.582082)
      </small>
    </b-form-group>
    <b-form-group>
      <PhoneNumber
        placeholder="Телефонный номер"
        :value="manufacturerForm.contactPersonPhone"
        @input="manufacturerForm.contactPersonPhone = $event"
        :country="countryISO"
        :readonly="!countrySelected"
        required
      ></PhoneNumber>
    </b-form-group>

    <b-form-group>
      <b-form-input placeholder="Вебсайт" v-model="manufacturerForm.webSite" required></b-form-input>
    </b-form-group>
  </FormWithActions>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { InnAutocomplete, PhoneNumber, AddressAutocomplete } from "@/components/forms/elements";
import FormWithActions from "./FormWithActions.vue";

export default {
  name: "ManufacturerForm",
  components: {
    InnAutocomplete,
    PhoneNumber,
    AddressAutocomplete,
    FormWithActions,
  },
  props: {
    mode: String,
    formData: Object,
  },
  data() {
    return {
      loading: false,
      manufacturerId: this.$route.params.id,
      manufacturerForm: { ...{ countryId: null, inn: null, name: null, address: null, coordinates: null }, ...this.formData },
      oldAddress: this.formData.address,
      suggestionAddress: null
    };
  },
  methods: {
    ...mapActions(["showErrorAlert"]),
    ...mapActions("countries", ["fetchCountries"]),
    ...mapActions("manufacturers", ["addManufacturer", "editManufacturer", "changeManufacturerData"]),

    onCountryChange() {
      this.manufacturerForm = {
        countryId: this.manufacturerForm.countryId,
        contactPersonFirstName: null,
        contactPersonLastName: null,
        contactPersonEmail: null,
        inn: null,
        name: null,
        address: null,
        coordinates: null,
        contactPersonPhone: null,
        webSite: null,
        latitude: null,
        longitude: null,
      };
    },

    onSelectInnSuggection(suggestion) {
      this.manufacturerForm.inn = suggestion.inn;
      this.manufacturerForm.name = suggestion.name;
      this.manufacturerForm.address = this.suggestionAddress = suggestion.address;
      this.manufacturerForm.latitude = suggestion.latitude;
      this.manufacturerForm.longitude = suggestion.longitude;
    },
    onSelectAddressSuggection(suggestion) {
      this.manufacturerForm.address = this.suggestionAddress = suggestion.address;
      this.manufacturerForm.latitude = suggestion.latitude;
      this.manufacturerForm.longitude = suggestion.longitude;
    },

    onSaveClick() {
      if (this.countryISO === 'RU' && this.manufacturerForm.address !== this.oldAddress && this.manufacturerForm.address !== this.suggestionAddress) {
        this.showErrorAlert("Адрес не соответствует предложенному списку адресов!");
        this.$nextTick(() => {
          const addressRef = this.$refs.address;
          addressRef.$el.firstChild.focus();
        });
        return;
      }
      this._saveManufacturer();
    },
    onCancelClick() {
      this._closeSideBar();
    },

    _saveManufacturer() {
      const payload = {
        ...this.manufacturerForm,
        latitude: this.manufacturerForm.latitude || parseFloat(this.manufacturerForm.coordinates?.split(', ').at(0)),
        longitude: this.manufacturerForm.longitude || parseFloat(this.manufacturerForm.coordinates?.split(', ').at(1)),
        countryId: this.manufacturerForm.countryId,
        countryCode: this.countryISO,
      };
      let method = null;
      let args = null;
      if (this.isAddMode) {
        method = this.addManufacturer;
        args = payload;
      } else if (this.isEditMode) {
        if (this.isChangeProfileData) {
          method = this.changeManufacturerData;
          args = { id: this.manufacturerForm.id, payload: payload };
        } else {
          method = this.editManufacturer;
          args = payload;
        }
      }
      this.loading = true;
      method(args).then((res) => {
        this.loading = false;
        if (!res.ok) return;

        this.$emit("saved:form-data");

        this._closeSideBar();
      });
    },
    _closeSideBar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
  },
  computed: {
    ...mapGetters(["userRole", "isAdmin"]),
    ...mapState({
      user: (state) => state.auth.user,
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapGetters("countries", ["countriesDD"]),

    isAddMode() {
      return this.mode === "ADD";
    },
    isEditMode() {
      return this.mode.includes("EDIT");
    },
    isAdminEditMode() {
      return this.isAdmin && this.mode === "EDIT";
    },
    isChangeProfileData() {
      return this.mode === "EDIT:PROFILE";
    },

    countrySelected() {
      return this.manufacturerForm.countryId != null;
    },
    countryISO() {
      return ((this.countriesDD || []).find((c) => c.value === this.manufacturerForm.countryId) || {}).iso;
    },
  },
  created() {
    this.fetchCountries(this.user.regionId).then((res) => {
      if (res.data.length === 1) this.manufacturerForm.countryId = res.data[0].id;
    });
  },
};
</script>

<style scoped></style>
