<template>
  <AutocompleteInput
    :key="country"
    v-on="inputListeners"
    :placeholder="innPlaceholder"
    :value="value"
    suggestion-field="inn"
    :validate-rule="{pattern: '[0-9]', range: innRange}"
    :query-func="queryFunction"
    :validate-func="innValid"
    :required="required"
    :disabled="disabled"
    :readonly="readonly"
    @input="onInput"
  >
    <template #suggestion="{ suggestion, query }">
      <div><HighlighterIf :text="suggestion.inn" :words="query" />&nbsp;&nbsp;{{ suggestion.name }}</div>
      <div>{{ suggestion.address }}</div>
    </template>
  </AutocompleteInput>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isNumber } from "@/utils";
import AutocompleteInput from "./AutocompleteInput.vue";
import HighlighterIf from "./HighlighterIf.vue";
import { INN_MASKS, INN_PLACEHOLDERS, INN_LENGTH } from "@/constants";

export default {
  name: "InnAutocomplete",
  components: {
    AutocompleteInput,
    HighlighterIf
  },
  props: {
    value: String,
    country: [Number, String],
    placeholder: String,
    findInn: { type: Function, default: () => Promise.resolve({}) },
    findInnSugestions: Array,
    required: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      findInternalInn: !!this.findInn,
    };
  },
  methods: {
    ...mapActions("dadata", ["fetchOrganizations"]),
    queryFunction(query) {
      return this.findInn({ inn: query, countryID: this.countryID }).then((res) => {
        this.findInternalInn = res.ok && res.data.length > 0;
        this.$emit('inn-exists', this.findInternalInn)
        if (this.findInternalInn) {
          return res.data
        }
        else {
          return this.fetchOrganizations({
            searchField: "inn",
            countryIsoCode: this.country,
            // countryId: this.country,
            query: query,
          }).then((res) => {
            return res.ok ? res.data : []
          });
        }
      });
    },
    innValid(value) {
      return value && value.length > 0 &&
             isNumber(value)
    },
    onInput(evt) {
      const max = INN_LENGTH[this.country] && INN_LENGTH[this.country].max ? INN_LENGTH[this.country].max : 12
      this.$emit("input", evt.slice(0, max))
    }
  },
  computed: {
    ...mapGetters("countries", ["countriesDD"]),
    innMask() {
      return INN_MASKS[this.country] || "";
    },
    innPlaceholder() {
      return INN_PLACEHOLDERS[this.country] || this.placeholder || "ИНН";
    },
    innRange() {
      const minmax = INN_LENGTH[this.country] || {min: 12, max: 12}
      const shouldBe = minmax.min === minmax.max ? `${minmax.max}` : `${minmax.min} или ${minmax.max}`
      return {...minmax, ...{message: `${this.innPlaceholder} должен быть ${shouldBe} цифр`}}
    },
    inputListeners() {
      return Object.assign({},
        this.$listeners,
        {
          input: this.onInput
        }
      )
    },
    countryID() {
      return ((this.countriesDD || []).find((c) => c.iso === this.country) || {}).value;
    },
  },
};
</script>
