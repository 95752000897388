<template>
  <div v-bind="$attrs">
    <div class="mb-2 row" v-if="hasHeaderActions">
      <div class="col">
        <slot name="header-actions"/>
      </div>
    </div>
    <div class="mb-2 row">
      <div class="col-auto">
        <div class="pl-3 row">
          <div class="col-auto mt-2">Строк:</div>
          <div class="col-auto">
            <b-form-select v-model="fetchParams.rows" :options="this.perPageDD" @change="onSelectPerPage"></b-form-select>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <slot name="filters" :filters="fetchParams" :change-filter="onChangeFilter"></slot>
      </div>
      <div class="text-right col">
        <slot name="filters-actions"></slot>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <b-table
          ref="selectableTable"
          hover
          selectable
          select-mode="single"
          :items="items"
          :fields="fields"
          :no-local-sorting="true"
          @row-selected="onRowSelected"
          @sort-changed="onSortChanged"
        ></b-table>
        <div v-if="!totalRows" class="text-center">Нет записей</div>
      </div>
    </div>

    <div class="row" v-if="isSelected">
      <div class="col">
        <slot name="row-actions"></slot>
      </div>
    </div>

    <div class="mt-4 row">
      <div class="col-auto" v-if="fetchParams.rows > 0">
        <b-pagination v-if="totalRows" v-model="currentPage" first-number last-number :total-rows="totalRows" :per-page="fetchParams.rows" @change="onChangePage"></b-pagination>
      </div>
      <div class="mt-2 col" v-if="totalRows && fetchParams.rows > 0">Записи с {{ beginRow }} по {{ lastRow }} из {{ totalRows }}</div>
      <div class="mt-2 col" v-if="totalRows && fetchParams.rows < 0">Всего {{ totalRows }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "../utils";

const PER_PAGE = 10;

export default {
  name: "TableWithPagination",
  props: {
    fields: {
      type: Array
    },
    items: {
      type: Array
    },
    totalRows: {
      type: Number
    }
  },
  data() {
    return {
      table: null,
      perPageDD: [5, 10, 20, 50, 100, {value: -1, text: 'Все'}],
      fetchParams: {
        first: 0,
        rows: PER_PAGE
      },
      currentPage: 1,
      selectedRow: null
    };
  },
  methods: {
    ...mapActions('tables', ['saveParams']),
    onSelectPerPage() {
      this.fetchParams.first = 0;
      this.emitChangeParamsDebounced(this.fetchParams, true);
    },
    onChangeFilter(prop, value) {
      this.fetchParams[prop] = value
      this.fetchParams.first = 0;      
      this.emitChangeParamsDebounced(this.fetchParams, true);
    },
    onSortChanged(ctx) {
      const sortBy = ctx.sortBy[0].toUpperCase() + ctx.sortBy.slice(1);
      this.fetchParams.SortField = sortBy;
      this.fetchParams.Direction = ctx.sortDesc ? 1 : 0;
      this.fetchParams.first = 0;
      this.emitChangeParamsDebounced(this.fetchParams, true);
    },
    onChangePage(page) {
      this.fetchParams.first = (page -1) * this.fetchParams.rows;
      this.emitChangeParamsDebounced(this.fetchParams, true);
    },
    onRowSelected(items) {
      this.selectedRow = (items || [])[0]
      this.$emit("row-selected", this.selectedRow);
    },
    reload() {
      this.emitChangeParamsDebounced(this.fetchParams, false);
    },
    
    emitChangeParams(params, save) {
      this.$emit("change-params", params);
      if (save)
        this.saveParams({table: this.table, params: Object.assign({}, params)})
    }
  },
  computed: {
    ...mapState('tables', {
      params: state => state.params
    }),
    hasHeaderActions() {
      return !!this.$slots["header-actions"]
    },
    beginRow() {
      return (this.currentPage - 1) * this.fetchParams.rows + 1;
    },
    lastRow() {
      return this.currentPage * this.fetchParams.rows > this.totalRows ? this.totalRows : this.currentPage * this.fetchParams.rows;
    },
    isSelected() {
      return !!this.selectedRow
    },
    emitChangeParamsDebounced() {
      return debounce(this.emitChangeParams, 300)
    }
  },
  created() {    
    this.table = this.$vnode.data.ref;
    this.fetchParams = Object.assign({}, (this.params[this.table] || {
      first: 0,
      rows: PER_PAGE
    }))
    this.currentPage = (this.fetchParams.first / this.fetchParams.rows) + 1
    this.$emit("change-params", this.fetchParams);
  }

};
</script>

<style lang="scss" scoped></style>
