<template>
  <div>
    <div class="pl-3 mb-4 ml-1 row">
      <div class="col-4">
        <b-form-textarea
          id="textarea"
          :value="message"
          placeholder="Добавить сообщение"
          rows="4"
          @input="onImputMessage"
        ></b-form-textarea>
      </div>
    </div>

    <div class="mb-5 row">
      <div class="pl-5 col">
        <b-button variant="success" class="mr-3" @click="$emit('confirm', $event)">Подтвердить</b-button>
        <b-button v-if="[2, 4].includes(requestType)" variant="warning" type="submit" class="mr-3" @click="$emit('rejectToCorrection', $event)">
          Отклонить на корректировку
        </b-button>
        <b-button variant="danger" type="submit" @click="$emit('reject', $event)">Отклонить безвозвратно</b-button>
        <span class="ml-3 spinner" v-if="loading">
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ApproveRejectForm",
  props: {
    loading: Boolean,
    requestType: Number,
    message: String,
    onConfirm: Function,
    onRejectToCorrection: Function,
    onReject: Function
  },
  methods: {
    onImputMessage(event) {
      this.$emit("input", event)
    }
  },

};
</script>

<style lang="sass" scoped></style>
