import { HttpService } from "../HttpService";

export class ManufacturerCertificatesService extends HttpService {

  isManufacturerInCertifyProcess = async id => {
    return await this._$get(`/manufacturerscertificates/isincertificationprocess?manufacturerId=${id}`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  }

  getManufacturerNewIssuanceCertificationRequest = async id => {
    return await this._$get(`/manufacturerscertificates/getnewissuancecertificaterequest?manufacturerId=${id}`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  createManufacturerCertificateByRequestId = async requestId => {
    return await this._$put(`/manufacturerscertificates/${requestId}/createcertificate`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  rejectManufacturerCertificateByRequestId = async requestId => {
    return await this._$put(`/manufacturerscertificates/${requestId}/rejectcertificatecreation`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  getCertificateInfoByCurrentUser = async () => {
    return await this._$get(`/manufacturerscertificates/getcertificateinfo`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };
  
  getCertificateInfoByIds = async ({id, certificateId}) => {
    return await this._$get(`/manufacturerscertificates/${id}/getcertificateinfo/${certificateId}`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  getCertificateInfoByGuid = async certificateKey => {
    return await this._$get(`/manufacturerscertificates/getcertificateinfobykey/${certificateKey}`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  getCertificatesHistory = async () => {
    return await this._$get("/manufacturerscertificates/getcertificateshistory")
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  getManufacturerHistory = async id => {
    return await this._$get(`/manufacturerscertificates/${id}/getcertificateshistory`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  getCertificateQr = async id => {
    return await this._$get(`/manufacturerscertificates/${id}/getcertificateqrcode`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  certifyManufacturer = async payload => {
    return await this._$put("/manufacturerscertificates/certifymanufacturer", {body: payload})
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  revocationManufacturer = async payload => {
    return await this._$put("/manufacturerscertificates/revocation", {body: payload})
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  certifyAfterAdd = async () => {
    return await this._$put(`/manufacturerscertificates/certifyafteradd`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  getSelfRetailCertificatesByManufacturerId = async id => {
    return await this._$get(`/manufacturerscertificates/${id}/selfretails/certificates`).catch((err) => console.error(`$HttpService Error::${err}`))
  };
  
  getSelfRetailCertificateIdByManufacturerId = async ({id, certificateId}) => {
    return await this._$get(`/manufacturerscertificates/${id}/selfretails/certificates/${certificateId}`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  certifyReRelease = async id => {
    return await this._$post(`/manufacturerscertificates/${id}/rerelease`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

  fetchCertifyReReleaseHistory = async id => {
    return await this._$get(`/manufacturerscertificates/${id}/history`)
      .catch((err) => console.error(`$ManufactorerCertificateService Error::${err}`))
  };

}