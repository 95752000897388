import { HttpService } from "../HttpService";

export class RegionsService extends HttpService {

  fetchRegions = async () => {
    return await this._$get("/regions").catch((err) => console.error(`$HttpService Error::${err}`))
  };

  fetchCountries = async (id) => {
    return await this._$get(`/regions/${id}/countries`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

}