import RehauStore from "@/store/lib";

const tables = new RehauStore({
  state: {
    params: {}
  }
})
.action("saveParams", {
  serviceFn: (arg) => {return {ok: true, data: arg}},
  onSuccess: (state, {data}) => {
    state.params[data.table] = data.params
  }
})
.getStore()

export default tables;
