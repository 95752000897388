const partnersRoutes = [
  {
    path: '/partners',
    name: 'Partners',
    component: () => import('@/views/partners/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/partners/:id',
    name: 'PartnerDetails',
    component: () => import('@/views/partners/_id.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/partner/verify',
    name: 'PartnerVerify',
    component: () => import('@/views/partners/verify.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/offices',
    name: 'PartnerOffices',
    component: () => import('@/views/partners/offices.vue'),
    meta: { requiresAuth: true },
  },
]

export default partnersRoutes