import RehauStore from "@/store/lib";
import { Messages } from '@/constants';
import { 
  ManufacturersService, 
  ManufacturerCertificatesService, 
  DealersService, 
  DealerCertificatesService, 
  SelfRetailCertificatesService,
  RequestsService
} from "@/services";

const manufacturersService = new ManufacturersService()
const manufacturerCertificatesService = new ManufacturerCertificatesService()
const dealersService = new DealersService()
const dealerCertificatesService = new DealerCertificatesService()
const selfRetailCertificatesService = new SelfRetailCertificatesService()
const requestsService = new RequestsService()

const certificates = new RehauStore({
  state: {
    confirmed: false,
    isInCertificationProcess: false,
    newIssuanceCertificationRequest: [],

    partner: {},
    certificate: {}, 
    certificatesHistory: [],
    certificateHistoryTotal: null,
    
    addressCertificate: {}, 
    addressCertificatesHistory: [],
    addressCertificatesHistoryTotal: null,
    
    selfretailExist: false,
    showAddNewSelfRetailForm: false,

    selfretail: {},
    selfretailCertificate: {}, 
    selfretailCertificateHistory: [],
    selfretailCertificateHistoryTotal: null,

    isSelfretailHasActiveRequest: false,
    
    manufacturerManager: null,
    activeCertificateGuid: null

  }
})
.action("manufacturerVerify", {
  serviceFn: manufacturersService.manufacturerVerify,
  onSuccess: (state, {data}) => {
    state.confirmed = data.confirmed
  },
  onError: (state, {error, context}) => {
    context.dispatch("showWarningAlert", error, {root: true});
    state.confirmed = false
  }
})
.action("fetchManufacturerCertificate", {
  serviceFn: manufacturerCertificatesService.getCertificateInfoByCurrentUser,
  property: "certificate" ,
  onError: (state) => {
    state.certificate = {isRevoked: true}
  } 
})
.action("fetchManufacturerCertificateByKey", {
  serviceFn: manufacturerCertificatesService.getCertificateInfoByGuid,
  property: "certificate",
  onError: (state) => {
    state.certificate = {isRevoked: true}
  }
})
.action("fetchManufacturerIdCertificateId", {
  serviceFn: manufacturerCertificatesService.getCertificateInfoByIds,
  property: "certificate",
  onError: (state) => {
    state.certificate = {isRevoked: true}
  }  
})
.action("fetchManufacturerIdSelfRetailCertificateId", {
  serviceFn: manufacturerCertificatesService.getSelfRetailCertificateIdByManufacturerId,
  property: "selfretailCertificate"  
})
.action("fetchManufacturerCertificatesHistory", {
  serviceFn: manufacturerCertificatesService.getCertificatesHistory,
  onSuccess: (state, {data}) => {
    state.certificatesHistory = data.certificatesHistory
    state.certificateHistoryTotal = data.certificatesHistory.length
    state.manufacturerManager = data.managerFullName

    let active = data.certificatesHistory.filter(
      (item) => item.status == "Активен"
    )[0];
    if (active) state.activeCertificateGuid = active.key
  }
})
.action("fetchAddressCertificate", {
  serviceFn: dealerCertificatesService.getCertificateInfoByCurrentUserAndAddress,
  property: "addressCertificate",
  onError: (state) => {
    state.addressCertificate = {isRevoked: true}
  }
})
.action("fetchAddressCertificateByKey", {
  serviceFn: dealerCertificatesService.getCertificateInfoByGuid,
  property: "addressCertificate",
  onError: (state) => {
    state.addressCertificate = {isRevoked: true}
  }
})
.action("fetchDealerIdCertificateById", {
  serviceFn: dealerCertificatesService.getCertificateInfoByIds,
  property: "addressCertificate",
  onError: (state) => {
    state.addressCertificate = {isRevoked: true}
  }
})
.action("fetchAddressCertificateHistory", {
  serviceFn: dealersService.fetchAddressCertificateHistory,
  onSuccess: (state, {data}) => {
    state.addressCertificatesHistory = data
    state.addressCertificatesHistoryTotal = data.length
  }
}) 
.action("fetchPartner", {
  serviceFn: dealersService.fetchPartner,
  property: "partner"
})
.action("fetchSelfRetailCertificate", {
  serviceFn: selfRetailCertificatesService.fetchSelfRetailCertificate,
  property: "selfretailCertificate",
  onError: (state) => {
    state.selfretailCertificate = {isRevoked: true}
  }
})
.action("fetchSelfRetailCertificateByKey", {
  serviceFn: dealerCertificatesService.getCertificateInfoByGuid,
  property: "selfretailCertificate",
  onError: (state) => {
    state.selfretailCertificate = {isRevoked: true}
  }
})
.action("fetchSelfRetailCertificateHistory", {
  serviceFn: selfRetailCertificatesService.fetchSelfRetailCertificates,
  onSuccess: (state, {data}) => {
    state.selfretailCertificateHistory = data.items
    state.selfretailCertificateHistoryTotal = data.totalItems
  }
})
.action("fetchSelfRetail", {
  serviceFn: manufacturersService.fetchSelfRetail,
  property: "selfretail"
})
.action("fetchSelfretailHasActiveRequest", {
  serviceFn: selfRetailCertificatesService.selfretailCertificateHasActiveRequest,
  property: "isSelfretailHasActiveRequest"
})

.action("isManufacturerInCertificationProcess", {
  serviceFn: manufacturerCertificatesService.isManufacturerInCertifyProcess,
  property: "isInCertificationProcess"
})
.action("getManufacturerNewIssuanceCertificateRequest", {
  serviceFn: manufacturerCertificatesService.getManufacturerNewIssuanceCertificationRequest,
  onSuccess: (state, {data}) => {
    state.newIssuanceCertificationRequest = [data]
  }
})

.action("createManufacturerCertificateByRequestId", {
  serviceFn: manufacturerCertificatesService.createManufacturerCertificateByRequestId,
  onSuccess: (state, {data}) => {
    console.log(data)
  }
})
.action("rejectManufacturerCertificateByRequestId", {
  serviceFn: manufacturerCertificatesService.rejectManufacturerCertificateByRequestId,
  onSuccess: (state, {data}) => {
    console.log(data)
  }
})
.action("isDealerInCertificationProcess", {
  serviceFn: dealerCertificatesService.isDealerInCertificationProcess,
  property: "isInCertificationProcess"
})
.action("fetchAddressCertificationRequests", {
  serviceFn: requestsService.addressCertificationRequests,
  onSuccess: (state, {data}) => {
    state.newIssuanceCertificationRequest = data
  }
})
.action("approveDealerCertificate", {
  serviceFn: dealerCertificatesService.approveDealerCertificate,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.DataConfirmed, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestConfirmed, {root: true});
  }
})
.action("rejectDealerCertificate", {
  serviceFn: dealerCertificatesService.rejectDealerCertificate,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    context.dispatch("showSuccessAlert", Messages.Success.RequestRejection, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RequestRejection, {root: true});
  }
})

.action("createSelfRetailCertify", {
  serviceFn: manufacturersService.createSelfRetailCertify,
  onSuccess: (state, {data, context}) => {
    console.log(data);
    state.selfretailExist = false;
    state.showAddNewSelfRetailForm = false;          
    context.dispatch("showSuccessAlert", Messages.Success.SelfRetailCertificateAdded, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.SelfRetailCertificateAdded, {root: true});
  }
})

.action("managerApproveCertificationSelfRetail", {
  serviceFn: requestsService.managerApproveCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);          
    context.dispatch("showSuccessAlert", Messages.Success.ApproveCertificationSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.ApproveCertificationSelfRetail, {root: true});
  }
})
.action("managerRejectCertificationSelfRetail", {
  serviceFn: requestsService.managerRejectCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);          
    context.dispatch("showSuccessAlert", Messages.Success.RejectCertificationSelfRetail, {root: true}); 
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectCertificationSelfRetail, {root: true});
  }
})
.action("leaderApproveCertificationSelfRetail", {
  serviceFn: requestsService.leaderApproveCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);          
    context.dispatch("showSuccessAlert", Messages.Success.ApproveCertificationSelfRetail, {root: true});
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.ApproveCertificationSelfRetail, {root: true});
  }
})
.action("leaderRejectCertificationSelfRetail", {
  serviceFn: requestsService.leaderRejectCertificationSelfRetail,
  onSuccess: (state, {data, context}) => {
    console.log(data);          
    context.dispatch("showSuccessAlert", Messages.Success.RejectCertificationSelfRetail, {root: true}); 
  },
  onError: (state, {context}) => {
    context.dispatch("showErrorAlert", Messages.Error.RejectCertificationSelfRetail, {root: true});
  }
})

.getStore()

export default certificates;