export const Role = {
  Admin: "Administrator",
  Manager: "Manager",
  Leader: "Leader",
  Curator: "Curator",
  Manufacturer: "Manufacturer",
  Dealer: "Dealer",
  translate: function(role) {
    if (role == this.Admin) return "Администратор";
    if (role == this.Manager) return "Менеджер";
    if (role == this.Leader) return "Руководитель";
    if (role == this.Curator) return "Куратор";
    if (role == this.Manufacturer) return "Производитель";
    if (role == this.Dealer) return "Партнер";
  }
};

export const RoleId = {
  Admin: 1,
  Administrator: 1,
  Manager: 2,
  Leader: 3,
  Curator: 4,
  Manufacturer: 5,
  Dealer: 6,
};
