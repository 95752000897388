export const DateFormat = {
  getDate: function(date) {
    if (date) {
      if (typeof date === "object")
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      else {
        let d = date.split("T")[0];
        d = d.split("-");
        return `${d[2]}-${d[1]}-${d[0]}`;
      }
    }
  }
};
