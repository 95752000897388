const requestsRoutes = [
  {
    path: '/requests',
    name: 'Requests',
    component: () => import('@/views/requests/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/requests/:id',
    name: 'RequestDetails',
    component: () => import('@/views/requests/_id.vue'),
    meta: { requiresAuth: true },
  }  
]

export default requestsRoutes