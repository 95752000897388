import { HttpService } from "../HttpService";

export class NotificationsService extends HttpService {

  fetchNotifications = async () => {
    return await this._$get("/notifications").catch((err) => console.error(`$HttpService Error::${err}`))
  };

  notificationViewed = async id => {
    return await this._$put(`/notifications/${id}/markasviewed`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

}