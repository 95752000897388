import RehauStore from "@/store/lib";
import { NotificationsService } from "@/services";

const notificationsServise = new NotificationsService()

const notifications = new RehauStore({
  state: {
    notifications: [],
    notReadedNotifications: []
  }
})
.action("fetchNotifications", {
  serviceFn: notificationsServise.fetchNotifications,
  property: "notifications",
  onSuccess: (state, {data}) => {
    state.notifications = data.items
    state.notReadedNotifications = data.items.filter((item) => item.isViewed == false)
  }
})
.action("notificationViewed", {
  serviceFn: notificationsServise.notificationViewed,
  onSuccess: (state, {context}) => {
    context.dispatch("fetchNotifications")
  }
})
.getter("hasNotReadedNotifications", state => state.notReadedNotifications.length > 0)
.getStore()

export default notifications;