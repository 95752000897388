<template>
  <div class="alertmsg">
    <b-alert dismissible :show="alertmsg.show" :variant="alertmsg.type">
      <template v-if="!!messages">
        <h5>{{message}}</h5>
        <ul>
          <li v-for="(m, i) in messages" :key="i"><h5>{{m}}</h5></li>
        </ul>
      </template>
      <template v-else>
        <h5>{{message}}</h5>
      </template>
    </b-alert>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "AlertMsg",
  data() {
    return {
      message: null,
      messages: null
    }
  },
  computed: mapState({ alertmsg: state => state.alertmsg.alertmsg }),
  watch: {
    alertmsg(n) {
      if (n.text.startsWith('[')) {
        this.messages = JSON.parse(n.text)
        this.message = this.messages.shift()
      }
      else this.message = n.text
    }
  },
};
</script>
<style lang="scss">
.alertmsg {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 47%;
  z-index: 1999;
}
</style>
