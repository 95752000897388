const selfretailsRoutes = [
  {
    path: '/selfretails',
    name: 'Selfretails',
    component: () => import('@/views/selfretails/index.vue'),
    meta: { requiresAuth: true },
  },      
  {
    path: '/selfretails/:id',
    name: 'SelfretailDetails',
    component: () => import('@/views/selfretails/_id.vue'),
    meta: { requiresAuth: true },
  }
]

export default selfretailsRoutes