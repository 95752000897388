<template>
  <div id="app" class="app">
    <Header />
    <div class="content container-fluid">
      <router-view/>
    </div>
    <alert-msg/>
  </div>
</template>

<script>
import '../src/assets/scss/app.scss'
import { Header, AlertMsg } from '@/components'

export default {
  name: 'App',
  components: {
    Header,
    AlertMsg
  }
}
</script>

<style lang="scss">
  .app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: hidden;
  }
  .content {
    flex: 1;
    overflow-y: auto ;
  }
</style>
