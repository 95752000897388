export const PartnerStatus = {
  New: "Новый",
  Correction: "Корректировка",
  Confirmed: "Подтвержден",
  translate: function(type) {
    if (type == 0) return this.New;
    if (type == 1) return this.Correction;
    if (type == 2) return this.Confirmed;
  }
};
