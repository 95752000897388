<template>
  <fragment>
    <highlighter
      v-if="highlight"
      :search-words="(words || '').split(' ')"
      :auto-escape="true"
      :text-to-highlight="text"
      highlight-class-name="highlight-color"
      highlight-tag="span"
    />
    <span v-else>{{ text }}</span>
  </fragment>
</template>

<script>
export default {
  name: "HighlighterIf",
  props: {
    highlight: {type: Boolean, default: true},
    text: String,
    words: String,
  },
};
</script>

<style scoped>
.highlight-color{
  color: rgb(68, 146, 209);
}
</style>
