export const RequestType = {
  AddManufacturer: "Добавление производителя",
  AddManufacturerCertificate: "Выдача сертификата производителю",
  AddDealer: "Добавление дилера",
  AddDealerCertificate: "Выдача сертификата дилеру",
  AddSelfRetail: "Добавление собственной розницы",
  AddSelfRetailCertificate: "Выдача сертификата на собственную розницу",
  translate: function(type) {
    if (type == 0) return this.AddManufacturer;
    if (type == 1) return this.AddManufacturerCertificate;
    if (type == 2) return this.AddDealer;
    if (type == 3) return this.AddDealerCertificate;
    if (type == 4) return this.AddSelfRetail;
    if (type == 5) return this.AddSelfRetailCertificate;
  }
};

export const RequestTypeId = {
  AddManufacturer: 0,
  AddManufacturerCertificate: 1,
  AddDealer: 2,
  AddDealerCertificate: 3,
  AddSelfRetail: 4,
  AddSelfRetailCertificate: 5
}

export const RequestStatus = {
  New: "Новый",
  ConfirmedByManager1: "Подтверждено менеджером",
  ConfirmedByManager1ExpectsManager2: "Менеджер 1 подтвердил, ожидает менеджера 2",
  ConfirmedByManager2: "Подтверждено менеджером 2",
  Agreed: "Согласована",
  Confirmed: "Подтверждена",
  Rejected: "Отклонена",
  RejectedForCorrection: "Отклонена на корректировку",
  translate: function(type) {
    if (type == 0) return this.New;
    if (type == 1) return this.ConfirmedByManager1;
    if (type == 2) return this.ConfirmedByManager1ExpectsManager2;
    if (type == 3) return this.ConfirmedByManager2;
    if (type == 4) return this.Agreed;
    if (type == 5) return this.Confirmed;
    if (type == 6) return this.Rejected;
    if (type == 7) return this.RejectedForCorrection;
  }
};

export const RequestStatusId = {
  New: 0,
  ConfirmedByManager1: 1,
  ConfirmedByManager1ExpectsManager2: 2,
  ConfirmedByManager2: 3,
  Agreed: 4,
  Confirmed: 5,
  Rejected: 6,
  RejectedForCorrection: 7
}