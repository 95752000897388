import { createStore } from "./Store";
import { Resource } from "./Resource";

export class RehauStore {
  constructor(options) {
    this.resource = new Resource(options)
    return this
  }

  action(actionName, options) {
    this.resource.action(actionName, options)
    return this
  }

  getter(getterName, getterFn) {
    this.resource.getter(getterName, getterFn)
    return this
  }

  getStore(options = {}) {
    return createStore(this.resource, options)
  }
}

export default RehauStore
