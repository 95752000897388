<template>
  <div class="wrapper" v-if="isMounted">
    <div class="mb-5 certificate-content">
      <div class="row">
        <div class="col pr-4">
          <div class="certificate-info">
            <div class="certif-status" :class="{'text-danger': !hasCertificate}">
              {{`Сертификат качества${noText}действителен.`}}<br />
              {{`Данный партнер${noText}сертифицирован компанией РЕХАУ.`}}
            </div>
            <div class="certif-data">
              <div class="certif-data__item d-flex justify-content-between">
                <div class="certif-data__label">Название<br />на сертификате</div>
                <div class="certif-data__value">
                  {{ certificateView.certificateName || 'нет данных' }}
                </div>
              </div>

              <div class="certif-data__item d-flex justify-content-between">
                <div class="certif-data__label">Юридическое<br />название</div>
                <div class="certif-data__value">
                  {{ certificateView.legalAdress  || 'нет данных' }}
                </div>
              </div>

              <div class="certif-data__item d-flex justify-content-between">
                <div class="certif-data__label">Адрес</div>
                <div class="certif-data__value">
                  {{ certificateView.address  || 'нет данных' }}
                </div>
              </div>

              <div class="certif-data__item d-flex justify-content-between" v-if="certificate.isShowManufacturerInfoInCertificate">
                <div class="certif-data__label">Название<br> производителя</div>
                <div class="certif-data__value">
                  {{ certificateView.manufacturerName || 'нет данных'}}
                </div>
              </div>

            </div>
          </div>
        </div>
        <div :class="[hasCertificate ? 'col' : 'col-12 col-lg-6']">
          <CertificateComponent
            v-if="hasCertificate"
            :title="certificate.certificateName"
            :role="role"
            :qr="certificate.certificateImage"
          />
          <img v-else src="@/assets/not-active-certificate.jpg" alt="Сертификат не найден" />
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
// @ is an alias to /src
import CertificateComponent from "@/components/CertificateComponent";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "CertificatePreview",
  components: {
    CertificateComponent,
    FooterComponent
  },
  props: {
    certificate: [Object, Array],
    role: String
  },
  data() {
    return {
      isMounted: false
    }
  },
  methods: {},
  computed: {
    hasCertificate() { return this.certificate && !this.certificate.isRevoked },
    noText() { return (!this.certificate || this.certificate.isRevoked) ? ' не ' : ' ' },
    certificateView() { return (this.hasCertificate) ? this.certificate : {} }
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.isMounted = true
    }, 300)
  },
};
</script>

<style lang="scss">
.certificate {
  font-family: "BrixSansBold", Arial, sans-serif;
  padding: 30px;
  width: 520px;
  min-height: 716px;
  font-weight: bold;
  border: solid 31px $lightgray;
  text-align: center;
  position: relative;
  padding-bottom: 90px;
  &__header {
    text-align: left;
  }
  &__slogan {
    font-size: 13px;
    font-weight: bold;
  }
  &__line {
    height: 6px;
    width: 115px;
    background-color: #000;
    position: relative;
    &::before {
      content: "";
      width: 39px;
      height: 6px;
      background-color: $green;
      display: block;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  &__title {
    font-size: 40px;
    font-weight: bold;
    padding-top: 35px;
    padding-bottom: 12px;
  }
  &__name {
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 3px;
  }
  &__descr {
    font-size: 17px;
    font-weight: bold;
  }
  &__bottom {
    font-size: 15px;
    line-height: 20px;
    font-weight: bold;
    padding-top: 2px;
  }
  &__image {
    padding: 29px;
    img {
      max-width: 210px;
      width: 100%;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__logo {
    width: 150px;
    left: 10px;
    position: relative;
  }
}
.certificate-content {
  padding-top: 50px;
  min-height: calc(100vh - 148px);
  margin: auto;
  max-width: 1080px;
  @media(max-width: 1020px) {
    padding-top: 30px;
    min-height: auto;
    max-width: 529px;
  }
}
.certif-status {
    border: solid 14px $green;
    color: $green;
    font-size: 31px;
    padding: 12px 58px 15px 28px;
    margin-bottom: 90px;
    font-family: "BrixSansBold",Arial, Helvetica, sans-serif;
    font-weight: bold;
    line-height: 37px;
    @media(max-width: 1020px) {
      padding: 10px 15px !important;
      line-height: 27px !important;
      font-size: 20px !important;
      border: solid 8px $green !important;
      margin-bottom: 10px !important;
    }
    &.text-danger {
      border-color: $red !important;
    }
}
.certificate-info {
  max-width: 520px;

  @media(max-width: 1020px) {
    padding-top: 0px !important;
  }
}
.certif-data {
  font-family: "BrixSansBold",Arial, sans-serif;
  font-size: 23px;
  line-height: 1.9rem;
  font-weight: bold;
  @media(max-width: 1020px) {
    font-size: 16px !important;
    line-height: 1.2rem !important;
    margin-bottom: 30px;
  }
  &__item {
    border-bottom: 3px solid #000;
    padding-top: 14px;
    padding-bottom: 15px;
    @media(max-width: 1020px) {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      border-bottom: 2px solid #000 !important;
    }
  }
  &__label {
    padding-right: 15px;
    min-width: 40%;
  }
  &__value {
    text-align: right;
  }
}

img {
  max-width: 100%;
  min-width: 100%;
}

.footer {
  font-family: "BrixSansBold", Arial, sans-serif;
  height: 100px;
  background: #000;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  a {
    color: $red;
  }
}
</style>
