<template>
  <div>
    <header class="mb-4 header">
      <div class="row">
        <div class="col d-flex align-items-center">
          <a :href="homeHRef" class="logo">
            <img src="../assets/logo_new.svg" alt="" />
          </a>
          <h1 class="h5">Центр сертификации партнеров
            <span v-if="showHeader && isAdmin">Кабинет Администратора</span>
            <span v-if="showHeader && isLeader">Кабинет Руководителя</span>
            <span v-if="showHeader && isCurator">Кабинет Куратора</span>
            <span v-if="showHeader && isManufacturer">Кабинет Производителя</span>
            <span v-if="showHeader && isManager">Кабинет Менеджера</span>
            <span v-if="showHeader && isDealer">Кабинет Партнера</span>
          </h1>
        </div>
        <div class="col-auto pt-2 pr-5 d-flex justify-content-end align-items-center" v-if="showHeader">
          <div class="messages">
            <div
              class="mr-3 user-mail"
              :class="[(hasNotReadedNotifications ? 'text-danger' : 'text-success')]"
              @click="showToast()"
            >
              <font-awesome-icon icon="envelope" />
            </div>
            <div class="messages-list">
              <b-toast id="notifications" title="Сообщения" static no-auto-hide>
                <ul class="messages-list-ul">
                  <li v-for="item in notifications" :key="item.id">
                    <div
                      class="message"
                      :class="{ message_new: !item.isViewed }"
                      @click="onMessageClick(item.id, item.isViewed)"
                    >
                      <div class="d-flex justify-content-between">
                        <div class="message__sender">
                          от <b>{{ item.senderFullName }}</b>
                        </div>
                        <div class="message__date">
                          {{ item.sendDate.slice(0, 10) }}
                        </div>
                      </div>
                      <div class="message__body">
                        {{ item.message }}
                      </div>
                    </div>
                  </li>
                </ul>
              </b-toast>
            </div>
          </div>

          <div class="header-user">
            <b-dropdown size="lg" variant="primary" no-caret>
              <template #button-content>
                {{ user.firstName[0] }}{{ user.lastName[0] }}
              </template>
              <li class="pt-1 pl-3 pr-3 text-nowrap">
                <h3 class="h5">
                  {{ user.firstName }} {{ user.lastName }}
                </h3>
              </li>
              <b-dropdown-item @click="goProfile()">Мои данные</b-dropdown-item>
              <b-dropdown-item @click="logout()">Выйти</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="row header-bottom" v-if="showHeader">
        <div class="col">
          <nav class="topmenu">
            <ul>
              <li v-if="this.isAdmin">
                <router-link to="/users">Пользователи</router-link>
              </li>
              <li v-if="isAdmin || isLeader || isManager || isCurator">
                <router-link :to="`/manufacturers`">Производители</router-link>
              </li>
              <li v-else-if="isManufacturer">
                <router-link to="/certificates">Сертификаты</router-link>
              </li>
              <li v-else-if="isDealer">
                <router-link to="/offices">Офисы продаж</router-link>
              </li>
              <li v-if="isAdmin || isLeader || isManager || isCurator || isManufacturer">
                <router-link :to="`/partners`">Партнеры</router-link>
              </li>
              <li v-if="isManufacturer">
                <router-link to="/selfretails">Собственная розница</router-link>
              </li>
              <li v-if="isAdmin || isLeader || isManager || isCurator || isManufacturer">
                <router-link :to="`/requests`">Запросы</router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      notReaded: null,
      homeHRef: '/'
    };
  },
  methods: {
    ...mapActions([
      "logOut", 
      "updateNotifications"
    ]),
    ...mapActions('notifications', [
      "notificationViewed"
    ]),
    logout() {
      this.logOut().then(() => this.$router.push("/login"));
    },
    goProfile() {
      this.$router.push("/profile").catch(() => {});
    },
    onMessageClick(id, isViewed) {
      if (!isViewed) this.notificationViewed(id);
    },
    showToast() {
      this.$bvToast.show("notifications");
      this.handleClick();
    },
    hideToast() {
      this.$bvToast.hide("notifications");
    },
    handleClick() {
      document.body.addEventListener("click", (e) => {
        let element = document.getElementById("notifications");
        if (element && !element.contains(e.target)) this.hideToast();
      });
    },
  },
  computed: {
    ...mapState({      
      user: state => state.auth.user,
      userIsAgree: state => state.auth.user.isAgree,
      notifications: state => state.notifications.notifications
    }),
    ...mapGetters([
      "userRole",
      "isAuth",
      "isAdmin",
      "isManager",
      "isDealer",
      "isLeader",
      "isManufacturer",
      "isCurator",
    ]),    
    ...mapGetters('notifications', [
      "hasNotReadedNotifications"
    ]), 
    isCertificateByKey() {
      return ['ManufacturerCertificatePreviewByKey','DealerCertificatePreviewByKey','SelfRetailsCertificatePreviewByKey'].includes(this.$route.name)
    },
    showHeader() {
      return !this.isCertificateByKey && this.isAuth && this.userIsAgree
    }
  },  
  created() {
    //this.updateNotifications();    
  },
};
</script>

<style lang="scss">
.header-bottom {
  border-bottom: 1px solid #dee2e6;
}
.logo {
  max-width: 250px;
  display: inline-block;
  img {
    width: 100%;
  }
}
.topmenu {
  ul {
    display: flex;
    list-style: none;
  }
}
.header-user {
  .dropdown-toggle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    font-size: 24px;
    padding: 0;
  }
}
.user-mail {
  width: 50px;
  height: 50px;
  svg {
    width: 100% !important;
    height: 50px;
  }
}

.messages-list {
  position: absolute;
  right: 45px;
  min-width: 350px;
  z-index: 10;
  &-ul {
    list-style: none;
    padding-left: 0;
  }
}
.message {
  cursor: pointer;
  margin-bottom: 5px;
  &__date {
    font-size: 11px;
  }
  &_new {
    color: $green;
  }
}
.toast-body {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
