import RehauStore from "@/store/lib";
import { jwtParse } from "@/helpers";
import { Messages, Role } from "@/constants";
import { AuthService, AdminService } from "@/services";

const authService = new AuthService();
const adminService = new AdminService();

const getTokenExpired = () => {
  return (jwtParse(localStorage.getItem("auth-token") || "").exp || 0) * 1000;
};

const updateNotifications = async context => {
  if (context.getters.isAuth) {
    context.dispatch("notifications/fetchNotifications");
    setTimeout(() => {
      //updateNotifications(context);
    }, 60000);
  } else console.log("Not authorized");
};

const auth = new RehauStore({
  state: {
    token: localStorage.getItem("auth-token") || "",
    tokenexp: getTokenExpired(),
    tokenexp_pending: false,
    user: JSON.parse(localStorage.getItem("auth-user")) || {},
    secret: null,
    checkPassToken: null,
    passCreated: null
  }
})
.action("logIn", {
  serviceFn: authService.loginRequest,
  onSuccess: (state, { data, params, context }) => {
    if (data.token) {
      const decode_token = jwtParse(data.token);
      if (decode_token) {
        state.user = {
          id: decode_token.Id * 1,
          role: decode_token.role,
          name: decode_token.unique_name,
          email: decode_token.email,
          firstName: decode_token.FirstName,
          lastName: decode_token.LastName,
          partnerId: decode_token.PartnerId * 1,
          regionId: decode_token.RegionId * 1,
          region: decode_token.RegionShortName,
          isAgree: decode_token.IsAgree === "True"
        };
        state.token = data.token;
        localStorage.setItem("auth-token", data.token);
        localStorage.setItem("auth-user", JSON.stringify(state.user));
        state.tokenexp = decode_token.exp * 1000;
        state.secret = params.password;
        context.dispatch("updateNotifications");
      } else {
        context.dispatch("showErrorAlert", Messages.Error.TokenCreated, { root: true });
      }
    }
  },
  onError: (state, { error, context }) => {
    const joerror = JSON.parse(error);
    context.dispatch("showErrorAlert", joerror.message, { root: true });
  }
})
.action("logOut", {
  serviceFn: authService.logOutRequest,
  onSuccess: (state, { context }) => {
    state.token = "";
    state.tokenexp = 0;
    state.user = {};
    localStorage.removeItem("auth-token");
    localStorage.removeItem("auth-user");
    context.commit("reset");
  }
})
.action("tokenExpired", {
  serviceFn: () => {
    return { ok: true };
  },
  onSuccess: (state, { context }) => {
    state.tokenexp_pending = true;
    state.token = "";
    state.tokenexp = 0;
    state.user = {};
    localStorage.removeItem("auth-token");
    localStorage.removeItem("auth-user");
    context.commit("reset");
    context.dispatch("showTokenExpiredAlert", Messages.Error.TokenExpired, { root: true });
  }
})
.action("updateUser", {
  serviceFn: user => {
    return { ok: true, data: user };
  },
  onSuccess: (state, { data }) => {
    state.user = data;
    localStorage.setItem("auth-user", JSON.stringify(data));
  }
})
.action("setIsAgree", {
  serviceFn: authService.setIsAgree,
  onSuccess: () => {}, // ???
  onError: () => {} // ???
})
.action("checkPasswordToken", {
  serviceFn: adminService.checkPasswordToken,
  onSuccess: (state, { data }) => (state.checkPassToken = data.success),
  onError: (state) => (state.checkPassToken = false)
})
.action("createPassword", {
  serviceFn: adminService.createPassword,
  onSuccess: (state, { data }) => (state.passCreated = data.success)
})
.action("resetPasswordByEmail", {
  serviceFn: adminService.resetPasswordByEmail,
  onSuccess: (state, { context }) => {
    context.dispatch("showSuccessAlert", Messages.Success.PasswordResetedByEmail, { root: true });
  },
  onError: (state, { error, context }) => {
    context.dispatch("showErrorAlert", error, { root: true });
  }
})
.action("updateNotifications", {
  actionFn: context => {
    setTimeout(() => {
      updateNotifications(context);
    }, 1000);
  }
})
.getter("isAuth", state => !!state.token)
.getter("getToken", state => state.token)
.getter("getTokenExp", state => state.tokenexp)

.getter("isAdmin", state => state.user.role == Role.Admin)
.getter("isManager", state => state.user.role == Role.Manager)
.getter("isLeader", state => state.user.role == Role.Leader)
.getter("isCurator", state => state.user.role == Role.Curator)
.getter("isManufacturer", state => state.user.role == Role.Manufacturer)
.getter("isDealer", state => state.user.role == Role.Dealer)
.getter("userRole", state => state.user.role.toLowerCase())

.getStore({ namespaced: false });

export default auth;
