import RehauStore from "@/store/lib";
import router from "@/router"

const TIME_OUT = 3000
const showAlert = async (state, context, data, timeout = TIME_OUT) => {
    state.alertmsg = data
    setTimeout(() => {
      context.dispatch('closeAlert')
    }, 
    timeout);
}
const showTokenExpiredAlert = async (state, context, data, timeout = TIME_OUT) => {
    state.alertmsg = data
    setTimeout(() => {
      context.dispatch('closeAlert')
      router.push('/login')
    }, 
    timeout);
}

const alertmsg = new RehauStore({
  state: {
    alertmsg: {
      text: "",
      type: "",
      show: false
    }
  }
})
.action("showSuccessAlert", {
  serviceFn: (msg) => {return {ok: true, data: msg}},
  onSuccess: (state, {data, context}) => showAlert(state, context, {text: data, type: 'success', show: true})
})
.action("showErrorAlert", {
  serviceFn: (msg) => {return {ok: true, data: msg}},
  onSuccess: (state, {data, context}) => showAlert(state, context, {text: data, type: 'danger', show: true})
})
.action("showTokenExpiredAlert", {
  serviceFn: (msg) => {return {ok: true, data: msg}},
  onSuccess: (state, {data, context}) => showTokenExpiredAlert(state, context, {text: data, type: 'danger', show: true})
})
.action("showWarningAlert", {
  serviceFn: (msg) => {return {ok: true, data: msg}},
  onSuccess: (state, {data, context}) => showAlert(state, context, {text: data, type: 'warning', show: true})
})
.action("closeAlert", {
  serviceFn: () => {return {ok: true}},
  onSuccess: (state) => state.alertmsg = {text: '', type: '', show: false}
})
.getStore({namespaced: false})

export default alertmsg;

