import RehauStore from "@/store/lib";
import { RegionsService } from "@/services"

const regionsService = new RegionsService()

const regions = new RehauStore({
  state: {
    countries: [],
    regions: [],
    messsage: '',
  }
})
.action("fetchRegions", {
  serviceFn: regionsService.fetchRegions,
  property: "regions"
})
.action("fetchCountries", {
  serviceFn: regionsService.fetchCountries,
  property: "countries"
})
.getter("countriesDD", state => {
  let res = state.countries.map(item => {
    return {value: item.id, text: item.name, iso: item.isoCode}
  });
  return res
})
.getter("regionsDD", state => {
  let res = state.regions.map(item => {
    return {value: item.id, text: item.shortName}
  });
  return res
})
.getStore()

export default regions;