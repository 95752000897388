<template>
  <b-modal size="lg" centered v-model="showModal" @hidden="onReject">
    <template #modal-header>
      <h5>Согласие на обработку персональных данных</h5>
    </template>
    <div>
      <b-form-checkbox ref="checkboxRef" required id="checkbox" v-model="isAgree" :value="true" :unchecked-value="false">
        Я прочитал(-а) и принял(-а) условия <a href="https://rhsolutions.ru/oferta/" class="link">Публичной оферты</a> и
        <a href="https://rhsolutions.ru/policy/" class="link">Политики по обработке персональных данных</a> и даю согласие на обработку своих персональных данных
      </b-form-checkbox>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="success" @click="onAgree" :disabled="!isAgree">
        СОГЛАСЕН
      </b-button>
      <b-button size="sm" variant="outline-secondary" @click="onReject">
        Не согласен
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "IsAgreeModal",
  props: {
    show: Boolean,
    user: Object
  },
  data() {
    return {
      showModal: this.show,
      isAgree: false
    };
  },
  methods: {
    ...mapActions(["logIn", "logOut", "setIsAgree"]),

    onAgree() {
      if (!this.isAgree) {
        const checkboxRef = this.$refs.checkboxRef;
        checkboxRef.focus();
      } else {
        this.setIsAgree().then(() => {
          this.logIn(this.user).then(r => {
            console.log(r);
            if (this.isAdmin) this.$router.push("/users");
            else if (this.isManager || this.isLeader || this.isCurator) this.$router.push(`/manufacturers`);
            else if (this.isManufacturer) this.$router.push("/certificates");
            else if (this.isDealer) this.$router.push("/offices");
            else this.$router.push("/");
          });
        });
      }
    },
    onReject() {
      this.logOut().then(() => this.$router.push("/login"));
    }
  },
  computed: {
    ...mapState({ userIsAgree: state => state.auth.user.isAgree }),
    ...mapGetters(["userRole", "isAuth", "isAdmin", "isLeader", "isManager", "isCurator", "isManufacturer", "isDealer"])
  }
};
</script>

<style lang="scss" scoped></style>
