const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/login.vue')   
  },
  {
    path: '/checkagree',
    name: 'CheckAgree',
    component: () => import('@/views/auth/checkAgree.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/forgotPassword.vue')
  },
  {
    path: '/admin/users/createpassword',
    name: 'CreatePassword',
    component: () => import('@/views/auth/createPassword.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/auth/profile.vue'),
    meta: { requiresAuth: true },
  },
]

export default authRoutes