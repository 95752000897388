export function debounce(fn, delay = 500) {
  let timeoutId;
  return (...args) => {
    // cancel the previous timer
    if (timeoutId) clearTimeout(timeoutId);
    // setup a new timer
    timeoutId = setTimeout(() => {
      fn.apply(null, args);
    }, delay);
  };
}

export function isObject(obj) {
  return obj != null && obj.constructor.name === "Object"
}

export function isNumber(value) {
  return /^[0-9]+$/.test(value);
}