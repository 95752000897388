const certificatesRoutes = [
  {
    path: '/certificates',
    name: 'ManufacturerCertificate',
    component: () => import('@/views/certificates/manufacturer/index.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/certificates/preview',
    name: 'ManufacturerCertificatePreview',
    component: () => import('@/views/certificates/manufacturer/preview.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/manufacturers/:id/certificates/:certificateId/preview',
    name: 'ManufacturerIdCertificatePreview',
    component: () => import('@/views/certificates/manufacturer/preview.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/offices/:id/certificates',
    name: 'DealerOfficeCertificate',
    component: () => import('@/views/certificates/dealer/index.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/offices/:id/certificates/preview',
    name: 'DealerOfficeCertificatePreview',
    component: () => import('@/views/certificates/dealer/preview.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/partners/:id/certificates/:certificateId/preview',
    name: 'PartnersCertificatePreview',
    component: () => import('@/views/certificates/dealer/preview.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/selfretails/:id/certificates',
    name: 'SelfRetailsCertificate',
    component: () => import('@/views/certificates/selfretails/index.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/selfretails/:id/certificates/preview',
    name: 'SelfRetailsCertificatePreview',
    component: () => import('@/views/certificates/selfretails/preview.vue'), 
    meta: { requiresAuth: true },
  },
  {
    path: '/manufacturers/:id/selfretails/certificates/:certificateId/preview',
    name: 'ManufacturerIdSelfRetailsCertificatePreview',
    component: () => import('@/views/certificates/selfretails/preview.vue'), 
    meta: { requiresAuth: true },
  },
  // anonimous
  {
    path: '/certificate',
    name: 'ManufacturerCertificatePreviewByKey',
    component: () => import('@/views/certificates/manufacturer/preview.vue')
  },
  {
    path: '/certificatedealer',
    name: 'DealerCertificatePreviewByKey',
    component: () => import('@/views/certificates/dealer/preview.vue')
  },
  {
    path: '/certificateselfretail',
    name: 'SelfRetailsCertificatePreviewByKey',
    component: () => import('@/views/certificates/selfretails/preview.vue')
  },

]

export default certificatesRoutes