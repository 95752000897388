<template>
  <FormWithActions :mode="mode" :loading="loading" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <InnAutocomplete
        placeholder="ИНН"
        v-model="fakeForm.inn"
        :country="countryISO"
        :readonly="!countrySelected"
        @select:suggestion="onSelectInnSuggection"
      ></InnAutocomplete>
    </b-form-group>

    <b-form-group>
      <FakeInn
        placeholder="Fake ИНН"
        v-model="fakeForm.fakeinn"
        :country="countryISO"
        :readonly="!countrySelected"
      ></FakeInn>
    </b-form-group>

    <b-form-group>
      <AddressAutocomplete
        placeholder="Адрес"
        v-model="fakeForm.address"
        :country="countryISO"
        :readonly="!countrySelected"
        @select:suggestion="onSelectAddressSuggection"
      ></AddressAutocomplete>
    </b-form-group>

    <b-form-group>
      <PhoneNumber
        placeholder="Телефонный номер"
        v-model="fakeForm.contactPersonPhone"
        :country="countryISO"
        :readonly="!countrySelected"
      ></PhoneNumber>
    </b-form-group>
  </FormWithActions>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { FakeInn, InnAutocomplete, AddressAutocomplete, PhoneNumber } from "@/components/forms/elements";
import FormWithActions from "./FormWithActions.vue";

export default {
  name: "fakeForm",
  components: {
    FormWithActions,
    FakeInn,
    InnAutocomplete,
    AddressAutocomplete,
    PhoneNumber
  },
  props: {
    mode: String,
    formData: Object,
  },
  data() {
    return {
      loading: false,
      fakeForm: { ...{ countryId: 5 }, ...this.formData },
      
    };
  },
  methods: {
    ...mapActions("countries", ["fetchCountries"]),

    onCountryChange() {},

    onSelectInnSuggection(suggestion) {
      this.fakeForm.inn = suggestion.inn;
      this.fakeForm.name = suggestion.name;
      this.fakeForm.address = suggestion.address;
    },
    onSelectAddressSuggection(suggestion) {
      this.fakeForm.address = suggestion;
    },

    onSaveClick() {
      this._saveManufacturer();
    },
    onCancelClick() {
      this._closeSideBar();
    },

    _saveManufacturer() {
      const method = (args) => {
        this._closeSideBar();
        return args;
      };
      const args = {
        payload: {
          ...this.fakeForm,
          ...{
            countryId: this.fakeForm.countryId,
            countryCode: this.countryISO,
          },
        },
      };
      method(args);
    },
    _closeSideBar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapGetters("countries", ["countriesDD"]),

    isEditMode() {
      return this.mode === "EDIT";
    },

    countryDesabled() {
      return this.isEditMode;
    },
    countrySelected() {
      return this.fakeForm.countryId != null;
    },
    countryISO() {
      return ((this.countriesDD || []).find((c) => c.value === this.fakeForm.countryId) || {}).iso;
    },
  },
  created() {
    this.fetchCountries(this.user.regionId).then((res) => {
      if (res.data.length === 1) this.fakeForm.countryId = res.data[0].id;
    });
  },
};
</script>

<style scoped></style>
