export const jwtParse = function parseJwt (token) {
    if (!token || token === "") return {}

    var base64Url = token.split('.')[1];
    const padding = '='.repeat((4 - base64Url.length % 4) % 4);
    const base64String = (base64Url + padding).replace(/-/g, '+').replace(/_/g, '/');

    var base64 = decodeURIComponent(atob(base64String).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(base64);
};