import { HttpService } from "../HttpService";

export class SelfRetailCertificatesService extends HttpService {

  // Self Retail Certification  
  fetchSelfRetailCertificates = async id => {
    return await this._$get(`/selfretailcertificates/${id}/certificates`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  fetchSelfRetailCertificate = async id => {
    return await this._$get(`/selfretailcertificates/${id}/certificate`).catch((err) => console.error(`$HttpService Error::${err}`))
  };
    
  selfretailCertificatePdf = async key => {
    return await this._$get(`/selfretailcertificates/${key}/pdf`).catch((err) => console.error(`$HttpService Error::${err}`))
  };
    
  selfretailCertificateRevocation = async ({key, payload}) => {
    return await this._$put(`/selfretailcertificates/${key}/revocation`, {body: payload}).catch((err) => console.error(`$HttpService Error::${err}`))
  };
  
  selfretailCertificateHasActiveRequest = async (id) => {
    return await this._$get(`/selfretailcertificates/${id}/hasactiverequest`).catch((err) => console.error(`$HttpService Error::${err}`))
  };

  fetchCertifyReReleaseHistory = async id => {
    return await this._$get(`/dealercertificates/${id}/history`)
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

}