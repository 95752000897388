<template>
  <div class="pl-5">
    <div v-if="isManufacturerConfirmed">Проверка...</div>
    <div v-if="!isManufacturerConfirmed">
      <h1 class="h4">Добавление производителя. Проверка данных.</h1>
      <br/>
      <ul class="mb-2 list">
        <li>
          <b>ИНН</b>
          <span>{{ this.manufacturer.inn }}</span>
        </li>
        <li>
          <b>Наименование</b>
          <span>{{ this.manufacturer.name }}</span>
        </li>
        <li>
          <b>Фактический адрес</b>
          <span>{{ this.manufacturer.address }}</span>
        </li>
        <li>
          <b>Контактное лицо</b>
          <span>{{ this.manufacturer.contactPerson }}</span>
        </li>
        <li>
          <b>Email</b>
          <span>{{ this.manufacturer.contactPersonEmail }}</span>
        </li>
        <li>
          <b>Телефон</b>
          <span>{{ this.manufacturer.contactPersonPhone }}</span>
        </li>
        <li>
          <b>Сайт</b>
          <span>{{ this.manufacturer.webSite }}</span>
        </li>
      </ul>

      <b-button :disabled="manufacturerStatus == 1" variant="danger" class="ml-4 mb-4" v-b-modal.modalMessage>Данные ошибочны</b-button>
      <b-button :disabled="manufacturerStatus == 1" variant="success" class="ml-4 mb-4"  @click="onDataCorrect">Подтверждаю</b-button>
      <span class="ml-4 mb-4 spinner" v-if="loading">
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </span>

      <b-modal id="modalMessage" title="Введите сообщение" ok-title="Отправить" cancel-title="Отмена" @ok="sendMessage" @cancel="cancelMessage">
        <b-form-textarea v-model="message" placeholder="Перечислите что необходимо изменить" maxlength="500" rows="9"></b-form-textarea>
      </b-modal>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";

export default {
  name: "ManufacturerVerify",
  data() {
    return {
      message: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions("manufacturers", ["manufacturerVerify", "fetchManufacturer", "manufacturerDataCorrect", "manufacturerDataWrong"]),

    async sendMessage() {
      let data = {
        manufacturerId: this.manufacturer.id,
        message: this.message,
      };
      console.log(data);
      this.loading = true;
      this.manufacturerDataWrong(data).then(() => {
        this.loading = false;
        this.fetchManufacturer(this.partnerId);
      });
    },
    cancelMessage() {
      this.message = "";
    },
    async onDataCorrect() {
      this.loading = true;
      await this.manufacturerDataCorrect({
        manufacturerId: this.manufacturer.id,
      }).then(() => {
        this.loading = false;
        if (this.isManufacturerConfirmed) this.$router.push("/certificates");
      });
    },
  },
  computed: {
    ...mapState({
      partnerId: (state) => state.auth.user.partnerId,
    }),
    ...mapState("manufacturers", {
      isManufacturerConfirmed: (state) => state.confirmed,
      manufacturer: (state) => state.manufacturer || {},
      manufacturerStatus: (state) => state.manufacturer.status || 0,
    }),
  },
  created() {
    if (this.partnerId) {
      this.manufacturerVerify().then(() => {
        if (this.isManufacturerConfirmed) this.$router.push("/certificates");
        else this.fetchManufacturer(this.partnerId);
      });
    }
  },
};
</script>

<style lang="scss">
.list {
  list-style: none;
  b {
    display: inline-block;
    min-width: 200px;
    padding-right: 15px;
  }
  li {
    margin-bottom: 20px;
    display: flex;
  }
}
</style>
