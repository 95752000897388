import { HttpService } from "../HttpService";

export class DealerCertificatesService extends HttpService {

  getDealerCertificateInfoByGuid = async certificateKey => {
    return await this._$get(`/dealercertificates/${certificateKey}/getcertificateinfobykey`)
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  revocationDealer = async payload => {
    return await this._$put("/dealercertificates/revocation", {body: payload})
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  }; 

  certifyDealer = async payload => {
    return await this._$post("/dealercertificates/certifydealer", {body: payload})
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  getCertificateInfoByCurrentUserAndAddress = async addressId => {
    return await this._$get(`/dealercertificates/getcertificateinfo/addresses/${addressId}`)
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  getCertificateInfoByIds = async ({id, certificateId}) => {
    return await this._$get(`/dealercertificates/${id}/getcertificateinfo/${certificateId}`)
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  getCertificateInfoByGuid = async certificateKey => {
    return await this._$get(`/dealercertificates/getcertificateinfobykey/${certificateKey}`)
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  managerConfirmDealerCertificate = async ({payload}) => {
    return this._$post(`/dealercertificates/manager1approve`, {body: payload})
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  managerRejectDealerCertificate = async ({payload}) => {
    return this._$post(`/dealercertificates/manager1reject`, {body: payload})
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  leaderConfirmDealerCertificate = async ({payload}) => {
    return this._$post(`/dealercertificates/leaderapprove`, {body: payload})
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  leaderRejectDealerCertificate = async ({payload}) => {
    return this._$post(`/dealercertificates/leaderreject`, {body: payload})
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  isDealerInCertificationProcess = async ({id, addressId}) => {
    let url = `/dealercertificates/isincertificationprocess/?dealerId=${id}&addressId=${addressId}`;
    return await this._$get(url).catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  rejectDealerCertificate = async id => {
    const url = `/dealercertificates/dealerrejected?requestId=${id}`;
    return this._$post(url).catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  approveDealerCertificate = async id => {
    const url = `/dealercertificates/dealerapprove?requestId=${id}`;
    return this._$post(url).catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  fetchPdf = async key => {
    return await this._$get(`/dealercertificates/pdf/${key}`).catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  checkForAnotherManufacturerRequest = async () => {
    let url = `/dealercertificates/checkforactiverequests`;
    return await this._$get(url).catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

  fetchCertifyReReleaseHistory = async id => {
    return await this._$get(`/dealercertificates/${id}/history`)
      .catch((err) => console.error(`$DealerCertificateService Error::${err}`))
  };

}