import RehauStore from "@/store/lib";
import { AddressesService } from "@/services";

const addressesService = new AddressesService()

const addresses = new RehauStore({
  state: {
    addresses: [],
  }
})
.action("fetchAddresses", {
  serviceFn: addressesService.fetchDaDataAddresses,
  property: "addresses"
})
.getStore()

export default addresses;