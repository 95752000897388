<template>
  <FormWithActions :mode="mode" :loading="loading" save-title="Сертифицировать" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <b-form-select v-model="requestCertificateForm.address" :options="officesDD">
        <template #first>
          <b-form-select-option :value="null" disabled>Фактический адрес</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
  </FormWithActions>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import FormWithActions from "./FormWithActions.vue";

export default {
  name: "RequestCertificateForm",
  components: {
    FormWithActions,
  },
  props: {
    mode: String,
    formData: Object,
  },
  data() {
    return {
      loading: false,
      dealerId: this.$route.params.id,
      requestCertificateForm: { ...this.formData },
    };
  },
  methods: {
    ...mapState({
      partnerId: state => state.auth.user.partnerId
    }),
    ...mapActions("partners", ["fetchManufacturerPartnerOffices", "certifyPartner"]),

    onSaveClick() {
      this._savePartner();
    },
    onCancelClick() {
      this._closeSideBar();
    },

    _savePartner() {
      const method = this.certifyPartner
      const args = {
        dealerId: this.dealerId,
        spotAddressId: this.requestCertificateForm.address
      }
      this.loading = true;
      method(args).then((res) => {
        this.loading = false;
        if (!res.ok) return;

        this.$emit("saved:form-data");

        this._closeSideBar();
      });
    },
    _closeSideBar() {
      this.$root.$emit("bv::toggle::collapse", "sidebar-right");
    },

  },
  computed: {
    ...mapGetters("partners", ["officesDD"])
  },
  created() {
    this.fetchManufacturerPartnerOffices({ id: this.$route.params.id, manufacturerId: this.partnerId() }).then((res) => {
      if (res.data.length === 1) this.requestCertificateForm.address = res.data[0].id;
    });
  },
};
</script>
