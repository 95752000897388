import RehauStore from "@/store/lib";
import { CountriesService } from "@/services"

const countriesService = new CountriesService()

const countries = new RehauStore({
  state: {
    countries: []
  }
})
.action("fetchCountries", {
  serviceFn: countriesService.fetchCountries,
  property: "countries"
})
.getter("countriesDD", state => {
  let res = state.countries.map(item => {
    return {value: item.id, text: item.name, iso: item.isoCode}
  });
  return res
})
.getStore()

export default countries;