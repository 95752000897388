<template>
  <b-form @submit.prevent="onFormSubmit">
    <slot/>
    <b-button v-if="saveVisible" variant="success" type="submit">{{saveTitleComp}}</b-button>
    <b-button v-if="cancelVisible" variant="danger" class="ml-2" @click="$emit('cancel-click')">Отмена</b-button>
    <span class="ml-3 spinner" v-if="loading">
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </span>
  </b-form>
</template>

<script>
export default {
  name: "FormWithActions",
  props: {
    loading: Boolean,
    mode: String,
    saveTitle: {
      type: String,
      default: null
    },
    saveVisible: {
      type: Boolean,
      default: true
    },
    cancelVisible: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    onFormSubmit() {
      this.$emit('save-click')
    }
  },  
  computed: {
    saveTitleComp() {
      return this.saveTitle || 
        (this.mode === 'ADD') ? 'Добавить' 
        : (this.mode === 'EDIT') ? 'Сохранить'
        : (this.mode === 'EDIT:PROFILE') ? 'Сохранить'
        : (this.mode === 'EDIT:SEND') ? 'Сохранить и отправить повторно'
        : '-SAVE-'
    }
  }
}
</script>

<style scoped></style>