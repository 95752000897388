<template>
  <FormWithActions :mode="mode" :loading="loading" @save-click="onSaveClick" @cancel-click="onCancelClick">
    <b-form-group>
      <b-form-input v-model="userForm.firstName" placeholder="Имя" required :disabled="isEditMode"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="userForm.lastName" placeholder="Фамилия" required :disabled="isEditMode"></b-form-input>
    </b-form-group>

    <b-form-group>
      <b-form-select v-model="userForm.roleId" :options="rolesOptions" @change="onRoleChange" required>
        <template #first>
          <b-form-select-option :value="null">Роль</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group v-if="isRegionsVisible">
      <b-form-select v-model="userForm.regionId" :options="this.regionsDD" :required="isRegionsVisible">
        <template #first>
          <b-form-select-option :value="null">Регион</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>

    <b-form-group>
      <b-form-input v-model="userForm.email" placeholder="Email" type="email" required :disabled="isEditMode"></b-form-input>
    </b-form-group>   

    <b-modal
      ref="modalChangeRegionConfirm"
      title="Подтвердите изменение региона"
      ok-title="Подтвердить"
      cancel-title="Отмена"
      centered
      @ok="onChangeRegionConfirm(true)"
      @cancel="onChangeRegionConfirm(false)"
    >
      Производители и партнеры привязаны к региону их менеджера.<br />
      При смене региона менеджеру его производители и партнеры перейдут в новый регион.
    </b-modal>
  </FormWithActions>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { RoleId } from '@/constants'
import FormWithActions from './FormWithActions.vue'

export default {
  name: "UserForm",
  components: {
    FormWithActions
  },
  props: {
    mode: String,
    formData: Object
  },
  data() {
    return {
      loading: false,
      userForm: {...{roleId: null, regionId: null}, ...this.formData},
      isRegionsVisible: this.formData.roleId !== RoleId.Admin && this.formData.roleId !== RoleId.Curator,
      selectedUserRoleId: this.formData.roleId,
      selectedUserRegionId: this.formData.regionId
    }
  },
  methods: {
    ...mapActions("regions", ["fetchRegions"]),
    ...mapActions("users", ["fetchRoles", "addUser", "updateUser"]),

    onRoleChange(ev) {
      this.isRegionsVisible = ev !== RoleId.Admin && ev !== RoleId.Curator;
    },

    onSaveClick() {
      if (this.isEditMode &&
          this.isRegionsVisible && this.userForm.regionId !== this.selectedUserRegionId && 
          this.userForm.roleId === this.selectedUserRoleId) {
        this.$refs["modalChangeRegionConfirm"].show();
        return;
      }
      if (!this.isRegionsVisible) {
        this.userForm.regionId = null
      }
      this._saveUser();
    },
    onCancelClick() {
      this._closeSideBar()
    },
    async onChangeRegionConfirm(confirm) {
      if (confirm) this._saveUser();
    },

    _saveUser() {
      const method = (!this.isEditMode ? this.addUser : this.updateUser)
      const args = !this.isEditMode ? this.userForm : { id: this.userForm.id, payload: this.userForm };
      this.loading = true
      method(args).then(res => {
        this.loading = false;
        if (!res.ok) return;
        
        this.$emit('saved:form-data')

        this._closeSideBar();
      });
    },
    _closeSideBar() {
      this.isRegionsVisible = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    }
  },
  computed: {
    ...mapGetters("users", ["rolesDD", "editRolesDD"]),
    ...mapGetters("regions", ["regionsDD"]),

    isEditMode() {
      return this.mode === 'EDIT'
    },
    rolesOptions() {
      return !this.isEditMode ? this.rolesDD : this.editRolesDD
    }
  },
  mounted() {
    this.fetchRegions()
  },
}
</script>

<style scoped></style>
